<template>
    <div class="longread-page">
        <div class="container">
            <Header @signInShow="openModal('signIn')" />
            <div class="news">
                <div class="news__top">
                    <div class="news__date">June 19, 2019</div>
                    <h1 class="news__title">The best smartphones for 2019</h1>
                </div>
                <div class="news__content">
                    <p class="big-size"><i>We tried all the latest and greatest smartphones to find the best of 2019</i></p>
                    <p>
                        Smartphones are perhaps the most personal tech we own. That’s why it’s super important to pick the phone that’s best for your needs. There are many factors to consider, from the design
                        and operating system to security and durability. We fully test every phone we can get our hands on here at Digital Trends, and after conducting hundreds of reviews, we’re confident
                        in recommending whether a particular phone is a good buy for you, or if you’re better off with something else.<br><br>
                        After much debate and soul searching, we landed on the Samsung Galaxy S10 and S10 Plus as our top pick for the best phone available right now, though for iOS fans,
                        Apple’s iPhone XS is still a close second. Here’s why the Galaxy S10 ultimately triumphed, and our picks for other smartphone categories. If you’re partial to the Android
                        mobile operating system, we’ve compiled a list of the best Android phones on the market.
                    </p>
                    <h2 class="news__subtitle">Best overall smartphone: Samsung Galaxy S10 or S10 Plus</h2>
                    <img src="/images/longread3-1-2x.png" class="sarafan">
                    <p class="news__img-caption">Photo by Julian Chokkattu/Digital Trends</p>
                    <p>
                        <b>Why you should buy this:</b> You get a stunning, elegant design, cutting-edge hardware, and a versatile camera.<br><br>
                        <b>Who it’s for:</b> Anyone who wants the best screen, backed by strong performance.<br><br>
                        <b>Why we picked the Galaxy S10 and S10 Plus:</b><br><br>
                        Samsung’s Galaxy S10 and S10 Plus are the culmination of years of refinement. The elegant, minimal design has evolved to be highly desirable, the software experience has been overhauled
                        to offer something slick and truly thoughtful, and the camera suite is one of the most versatile you’ll find.<br><br>
                        The first thing you’ll notice about the S10 or S10 Plus is the gorgeous Dynamic AMOLED screen. It covers almost all of the front of the phone, stretching to the edges and curving into
                        the metal frame, broken only by a hole-punch camera at the top right — single lens on the S10 and dual lens on the S10 Plus. That’s one of the few differences between these two phones,
                        so your preference really boils down to the size you want. The S10’s screen is 6.1 inches, while the S10 Plus jumps up to 6.4 inches and is definitely a little harder to handle. Both
                        are sharp, with HDR10+ certification, a wide resolution, and reduced blue light. Those spectacular screens are paired with stereo speakers, making these phones perfect for movie watching.<br><br>
                        You won’t see a fingerprint scanner in evidence, because Samsung has slipped it into the display. While you have to be quite precise, it’s the best in-display fingerprint sensor we’ve
                        tested so far. General performance is fluid and snappy thanks to the Qualcomm Snapdragon 855 processor, which is backed up by 8GB of RAM. There’s also 128GB of storage space and room
                        for a MicroSD card. Samsung also offers a 1TB version of the S10 Plus with a whopping 12GB of RAM.<br><br>
                        The One UI, which Samsung layers on top of Android 9.0 Pie, is miles away from TouchWiz and much improved. It’s generally easy to find everything you need and there are lots
                        of customization options. The only negative is that Samsung tends to be slow to update the underlying Android software, so you may have to wait a while for Android Q when it comes
                        out later this year.<br><br>
                        Most manufacturers have stepped up their photography game in recent years and Samsung is no exception. The main camera features three lenses, arranged horizontally on the back.
                        There’s a standard 12-megapixel lens with a variable f/1.5 to f/2.4 aperture, a 12-megapixel telephoto lens with a f/2.4 aperture, and a 16-megapixel ultra-wide-angle lens with
                        a f/2.2 aperture. This camera is equipped for every situation, whether you’re snapping friends in a nightclub, capturing a beautiful landscape, or zooming in on some action from afar.
                        The camera app is packed with tricks to help you get the most from your photos and videos. On the front, the S10 and S10 Plus sport a 10-megapixel lens with a f/1.9 aperture,
                        but on the S10 Plus it’s paired with an additional 8-megapixel lens with a f/2.2 aperture capable of capturing better depth information for that bokeh effect in selfies.<br><br>
                        Battery life is another strength, particularly in the S10 Plus, which has a 4,100mAh battery. The Galaxy S10 has a 3,400mAh battery. Sadly, both are limited to Quick Charge 2.0 speeds
                        for wired charging, but they can charge up pretty quickly via wireless charging pads and they can even share power with other Qi-certified devices, thanks to the Wireless PowerShare
                        feature. Both phones also boast IP68 ratings, so water is nothing to worry about.<br><br>
                        The prices may give you pause, at $900 and up for the S10 and $1,000 and up for the S10 Plus, but Samsung is giving you a lot for your money here. These are beautiful,
                        powerful phones that can do whatever you need them to.
                    </p>
                    <h2 class="news__subtitle">Best iPhone: Apple iPhone XS</h2>
                    <img src="/images/longread3-2-2x.png" class="sarafan">
                    <p class="news__img-caption">Photo by Julian Chokkattu/Digital Trends</p>
                    <p>
                        <b>Why you should buy this:</b> You want the most powerful, secure, and shutterbug-friendly iPhone ever.<br><br>
                        <b>Who it’s for:</b> Anyone who wants the best iOS experience.<br><br>
                        <b>Why we picked the iPhone XS:</b><br><br>
                        The iPhone XS is good at almost everything. It’s even better than the iPhone XS Max, because it’s the perfect size with its 5.8-inch screen.<br><br>
                        Speaking of its screen, the high-resolution OLED is energy-saving and colorful, with inky, deep blacks. The edge-to-edge, or “bezel-less,” design, makes watching videos and movies
                        feel a little more immersive. The all-glass design means you can wirelessly charge the iPhone XS, and it’s the most water-resistant iPhone, with an IP68 rating. The A12 Bionic processor
                        remains unmatched, with reliably fast performance when moving around the home screen or playing intensive augmented reality games using ARKit 2.0.<br><br>
                        The TrueDepth camera makes a return, and Face ID is even faster. Apple is still one of the only companies that has a secure facial-recognition tool that can log into all your sensitive
                        apps on a system level. This front-facing camera system also lets you make fun Animojis and Memojis, and the fluidity and tracking capabilities are still unmatched.
                        Then there’s Portrait Mode, which helps up your selfie game.<br><br>
                        The battery easily lasts a full day, though like with most flagship smartphones, heavy use will bring it perilously low.<br><br>
                        iOS 12 is smart, with artificial intelligence powering a lot of the new features. There are Siri Suggestions, where Siri can adapt to your usage patterns and make suggestions like
                        responding to a call or text when it’s been sitting idle for some time.  Grouped notifications also dramatically improve the way you interact with notifications, making them far
                        easier to deal with. On the iPhone XS, it all feels snappy and fluid.<br><br>
                        Add to all that Apple’s commitment to your security and privacy, instant software updates, and its industry-leading customer service that lets you walk into any Apple Store
                        and get help for free, and you have one great smartphone.<br><br>
                        The lack of a headphone jack is still one of our major issues with the iPhone XS. It comes with Lightning EarPods, but Apple decided to remove the 3.5mm-to-Lightning adapter
                        this year. It’s silly not to include it, considering you’re paying so much for the phone. Apple should also include a fast charging cable in the box, like every other manufacturer,
                        since the new iPhone supports it.<br><br>
                        This is the best time to buy an iPhone, and the iPhone XS is worth every penny. If you think the screen size is too small, spring for the iPhone XS Max, which has a 6.5-inch screen
                        for the best viewing experience and slightly better battery life.
                    </p>
                    <h2 class="news__subtitle">Best budget smartphone: Google Pixel 3a</h2>
                    <img src="/images/longread3-3-2x.png" class="sarafan">
                    <p class="news__img-caption">Photo by Julian Chokkattu/Digital Trends</p>
                    <p>
                        <b>Why you should buy this:</b> You’re on a strict budget, but you still want a powerful phone.<br><br>
                        <b>Who it’s for:</b> Bargain buyers who want a stock Android phone.<br><br>
                        <b>Why we picked the Google Pixel 3a:</b><br><br>
                        The Pixel 3a is a fantastically solid smartphone with no real weaknesses. Google’s affordable midrange phone looks a lot like its much more expensive flagship sibling,
                        but is finished in plastic instead of glass. There’s a 5.6-inch OLED display with a 2,220 x 1,080-pixel resolution that’s sharp and legible, even in broad daylight.<br><br>
                        The Qualcomm Snapdragon 670 processor is backed by 4GB of RAM and performs admirably. Part of the silky smoothness comes from the fact it features Android as Google intended,
                        which also ensures the very latest security patches and new Android versions as soon as they’re released.<br><br>
                        While midrange and budget smartphones have been growing more and more attractive and powerful, there are certain elements that always tend to be lacking and the camera is the main one.
                        Google’s Pixel 3a changes all that by making our favorite smartphone camera affordable. Google’s A.I. squeezes amazing results from the same single 12.2-megapixel lens you’ll find
                        in the Pixel 3; the Pixel 3a is just a tad slower at processing images. There’s also a capable 8-megapixel front-facing camera.<br><br>
                        The Pixel 3a comes complete with clever software features like Now Playing, which displays songs playing in your vicinity on the lock screen, and Call Screen, which enables
                        Google Assistant to screen your incoming calls.<br><br>
                        You get a decent 64GB of storage inside. There’s no space for a MicroSD card, but you do get a 3.5mm audio jack and a USB-C port for fast wired charging.
                        To round out an excellent phone, there’s a 3,000mAh battery that will easily see you through an average day with change.<br><br>
                        If we had to dig out a flaw, the lack of wireless charging is a little disappointing and there’s no IP rating, though Google says the Pixel 3a is splash- and dust-resistant.
                        If you’re sold, but you prefer a bigger screen, then the Pixel 3a XL gives you a bigger, 6-inch screen for an extra $80.
                    </p>
                    <h2 class="news__subtitle">Best camera phone: Google Pixel 3 and 3 XL</h2>
                    <img src="/images/longread3-4-2x.png" class="sarafan">
                    <p class="news__img-caption">Photo by Julian Chokkattu/Digital Trends</p>
                    <p>
                        <b>Why you should buy this:</b> It has the best smartphone camera you can buy.<br><br>
                        <b>Who it’s for:</b> Anyone who wants the best smartphone camera.<br><br>
                        <b>Why we picked the Google Pixel 3 and Pixel 3 XL:</b><br><br>
                        Nearly all flagship smartphone cameras these days are excellent, but Google’s Pixel 3 and Pixel 3 XL stand out by reliably capturing remarkable photographs. Both the Pixel 3 and 3 XL
                        have the same exact camera and features, so you can’t go wrong with either, but the smaller Pixel 3 is cheaper.<br><br>
                        The Pixel 3 phones have a 12.2-megapixel camera, but it’s Google’s image processing that does all the hard work. There’s virtually zero shutter lag, and while the processing takes
                        a second or two, the results are astonishing. The Pixel excels thanks to Google’s HDR+ technology, where the smartphone captures multiple photos at once, picks the best one, and layers
                        the rest to produce an image with the least amount of noise. This also eliminates overexposed or underexposed parts of an image, resulting in excellent dynamic range.<br><br>
                        Though there is only one camera on the rear, Google has a Portrait Mode, which blurs out the background behind a subject. It’s impressive what Google can do with just software,
                        as the camera can very accurately detect depth and the subject in the photo. It’s as good, if not better, than Portrait Mode on the iPhone XS and iPhone XR, and you can also control
                        the blur intensity after you snap the photo. There’s also Super Res Zoom, which uses artificial intelligence to improve digital zoomed photos. The Pixel 3 takes multiple photos
                        before and after you tap the shutter button, and because of this, Top Shot is a new feature that recommends the best frame in case you missed a perfect moment.<br><br>
                        A new Photobooth mode lets you place the camera down and make funny faces or smile, allowing you to free your hands as you and your friends take fun selfies, photobooth-style.
                        This uses A.I. to detect the best shots. A.I. is also used with an upcoming feature called Night Sight, which will make low-light photos look dramatically better. Even better,
                        Google has added an extra wide-angle camera on the front, so you can get way more in the shot — especially handy for group photos.<br><br>
                        The excellent camera experience is paired with Qualcomm’s Snapdragon 845 chip, which is still speedy, along with 4GB of RAM. As we mentioned earlier, Google’s software optimization
                        results in one of the most reliable Android phones ever made. Best of all? The Pixel 3 is water-resistant, and it gets automatic software updates directly from Google, so your phone
                        will always be up to date and perfectly safe. Google also offers 24/7 customer support right in the settings menu, so you can reach out to Google immediately if you have a problem.
                    </p>
                    <h2 class="news__subtitle">Best phone with great battery life: Huawei P30 Pro</h2>
                    <img src="/images/longread3-5-2x.png" class="sarafan">
                    <p class="news__img-caption">Photo by Andy Boxall/Digital Trends</p>
                    <p>
                        <b>Why you should buy this:</b> Smartphones are as good as they last, and you need a phone that can last more than a day.<br><br>
                        <b>Who it’s for:</b> People who need a phone that can last two days.<br><br>
                        <b>Why we picked the Huawei P30 Pro:</b><br><br>
                        You will find an impressively large 4,200mAh battery in the Huawei P30 Pro, which is enough to see you through two days before you need to find an outlet. It’s not just the big
                        battery that gives this phone such stamina — Huawei has also pulled off some clever software trickery to optimize power management. In addition to boasting a long-lasting battery,
                        the P30 Pro also supports quick charging, with Huawei’s proprietary Super Charge technology offering a full charge from empty in 70 minutes.<br><br>
                        There are no compromises under the hood to achieve the solid battery life. The P30 Pro is packing Huawei’s latest Hisilicon Kirin 980 processor, backed by 6GB of RAM with 128GB
                        of storage (an 8GB RAM version with 256GB of storage is also available). We found performance to be fast and smooth in our testing.ettings menu, so you can reach out to Google
                        immediately if you have a problem.<br><br>
                        The 6.47-inch OLED display is vivid and sharp, with a resolution of 2,340 x 1,080 pixels, and there is an excellent triple-lens main camera that combines 40-megapixel standard lens
                        with an 8-megapixel telephoto, as well as a 20-megapixel wide-angle. We also like the design, with a teardrop notch at the top of the screen for the 32-megapixel front-facing camera,
                        and a slim bezel at the bottom. There’s a reliable under-glass fingerprint sensor for securely unlocking the phone. If we had to find a weakness it would be Huawei’s EMUI user
                        interface — which still thankfully runs Android 9 Pie — and the fact this phone isn’t available in the U.S.<br><br>
                        You can check out our roundup of other smartphones with the best battery life for other alternatives.
                    </p>
                    <h2 class="news__subtitle">Best value flagship: OnePlus 7 Pro</h2>
                    <img src="/images/longread3-6-2x.png" class="sarafan">
                    <p class="news__img-caption">Photo by Andy Boxall/Digital Trends</p>
                    <p>
                        <b>Why you should buy this:</b> You get the luxurious feel and look of a flagship, with power to match, for less.<br><br>
                        <b>Who it’s for:</b> Anyone seeking a gorgeous, powerful, customizable, Android phone.<br><br>
                        <b>Why we picked the OnePlus 7 Pro:</b><br><br>
                        OnePlus has been turning out affordable phones with near flagship specs for years now and the OnePlus 7 Pro is its best release so far. The price has crept up, making it more
                        flagship than flagship killer, but this is an excellent smartphone by any standards.<br><br>
                        There’s a truly stunning 6.67-inch AMOLED screen with an impressive 3,140 x 1,440-pixel resolution, a buttery smooth 90Hz refresh rate, and support for HDR10+. It’s bright,
                        with deep blacks, and accurate colors. There isn’t even a notch, because the OnePlus 7 Pro features a pop-up selfie camera.<br><br>
                        That wonderful display is backed up by plenty of power with a Snapdragon 855 processor and your choice of 6GB, 8GB, or 12GB of RAM and 128GB or 256GB of storage. It’s one of the
                        fastest phones we’ve ever tested. It runs Android 9.0 Pie with Oxygen OS on top, which is packed with handy customization options.<br><br>
                        OnePlus has also worked hard to improve the camera, which has been a weak link for it in the past. The OnePlus 7 Pro has a triple-lens setup comprising a 16-megapixel ultra-wide
                        lens with a 117-degree field of view, a 48-megapixel lens with optical and electronic image stabilization (OIS and EIS), and an 8-megapixel telephoto lens also with OIS for a 3x
                        optical zoom or a 10x digital zoom.<br><br>
                        The 4,000mAh battery is enough for a busy day, but will likely need to be recharged at bedtime. Luckily, the Warp Charge fast charging system can take the OnePlus 7 Pro from zero
                        to 50% in just 20 minutes.<br><br>
                        If you’re looking for cons, then the absence of wireless charging, average battery life, and lack of an IP rating will figure, though OnePlus says the phone is water resistant.
                        All in all, though, it represents fantastic value for money.
                    </p>
                    <h2 class="news__subtitle">Best small smartphone: Samsung Galaxy S10e</h2>
                    <img src="/images/longread3-7.jpg" class="sarafan">
                    <p class="news__img-caption">Photo by Julian Chokkattu/Digital Trends</p>
                    <p>
                        <b>Why you should buy this:</b> It’s the perfect size for easy, one-handed operation.<br><br>
                        <b>Who it’s for:</b> Anyone seeking a smaller phone that still boasts great design and performance.<br><br>
                        <b>Why we picked the Samsung Galaxy S10e:</b><br><br>
                        Samsung has taken some of the best elements of the more expensive S10 and packed them into an even more compact and affordable body in the shape of the Galaxy S10e.
                        You’ll find a top-class 5.8-inch Dynamic AMOLED display with a 2,280 x 1,080-pixel resolution and HDR10+ certification. It lacks the curves of its bigger siblings, and it’s
                        not quite as sharp, but it offers excellent color accuracy and you won’t get tired of staring it at it.<br><br>
                        Inside, you get the same lightning fast Snapdragon 855 processor, which makes this phone capable of running all the latest games and apps. It comes with 6GB of RAM and 128GB of
                        storage and there’s room for expansion via the MicroSD card slot.<br><br>
                        Samsung has gone with a dual-lens main camera in the S10e, pairing a standard 12-megapixel lens with a variable f/1.5 to f/2.4 aperture and a 16-megapixel ultra-wide-angle
                        lens with a f/2.2 aperture. You’ll find a wide array of options and modes in the camera app, which also allows you to shoot 4K video in HDR. There’s a 10-megapixel lens with
                        a f/1.9 aperture around front.<br><br>
                        The 3,100mAh battery will get you through an average day, but it may need a top up if you’re planning to be out at night. Sadly wired charging isn’t very fast, but you can
                        wirelessly charge at the same speed. Ultimately, you get most of what makes Samsung’s Galaxy flagships special at a knockdown price with the S10e.<br><br>
                        If you still haven’t found what you’re looking for, then we also have ideas about the best rugged phones, best phones for kids, and the best gaming phones.
                    </p>
                    <h2 class="news__subtitle">What is an unlocked smartphone?</h2>
                    <p>
                        A locked smartphone is tied to a specific carrier’s network, so if your phone is locked to Verizon, for example, you can only use it with a Verizon SIM and service plan.
                        An unlocked smartphone can be used on any network. If you’ve paid in full for your phone, then it should be free to unlock. We have a guide that will show you how to unlock a phone
                        on every carrier. You can also buy some great unlocked phones that will work with any carrier straight out of the box.
                    </p>
                    <h2 class="news__subtitle">Can smartphones get viruses?</h2>
                    <p>
                        While it is possible to get viruses on iPhones or Android phones, what most people mean when they say virus is malware. Technically, the term virus means software that infects a host,
                        inserting itself into an existing program, and then spreads that infection by self-replicating. They are a very small percentage of malware and extremely rare on smartphones, but malware
                        isn’t. You should definitely think about how to protect your smartphone from hackers because a little common sense can save you from having to work out how to remove malware
                        from your phone.
                    </p>
                    <h2 class="news__subtitle">What should I do with my old smartphone?</h2>
                    <p>
                        The most obvious thing to do with your old smartphone is to pass it on to someone else. If you want to gift it, then make sure to wipe it first; we have guides on how to wipe your
                        Android phone and how to factory reset an iPhone. You may also want to turn that old phone into cash, in which case you’ll want to read up on how to sell your smartphone.
                        You may decide to keep it as a spare in case your new smartphone breaks, but you could also repurpose it with the right apps. It’s better to pass it on to someone who can use it
                        or find another use for it than let it languish in a drawer. If you can’t think of a good way to reuse your smartphone then consider donating it or recycling it.
                    </p>
                    <h2 class="news__subtitle">How long should a smartphone last?</h2>
                    <p>
                        Realistically, you should expect to get two to three years out of your smartphone. You can extend the life of any smartphone by taking proper care of it and it’s always worth
                        shopping for good protective cases. There are also loads of good waterproof phones to choose from nowadays. The problem with many Android phones is that the manufacturers and
                        carriers are slow to push out software updates, so the software can become dated and even insecure over time. Buy a phone from Apple or Google and you won’t have that problem.
                    </p>
                    <h2 class="news__subtitle">Do phones cause cancer?</h2>
                    <p>
                        The question of whether cell phone radiation is dangerous is still hotly contested in some quarters. It is technically classified as “possibly carcinogenic to humans”
                        but that puts it in the same category as coffee, gasoline, and nickel. There have been many studies, but we still lack any scientific evidence to provide a definite answer
                        to the question one way or the other. Most official bodies, including the Federal Communications Commission, Center for Disease Control, and National Cancer Institute agree there’s
                        no proven link right now, but also acknowledge that we need more research.
                    </p>
                    <h2 class="news__subtitle">How do prepaid phones work?</h2>
                    <p>
                        Prepaid cell phones work just like any other phone, but they come without a monthly contract or long-term commitment. You usually pay for service in advance. When you buy a prepaid
                        phone they tend to come with a fixed number of minutes, text messages, and possibly a fixed amount of mobile data. This removes the chance of any overage charges. When you run out,
                        you simply top up by buying more minutes, texts, and data in blocks.
                    </p>
                    <h2 class="news__subtitle">Which OS is best for you?</h2>
                    <p>
                        This is where things get personal. Picking a smartphone operating system is a huge deal. You’re buying into an entire ecosystem of apps and compatible products when you choose
                        between Android and iOS.<br><br>
                        Apple’s iOS is a beautiful, simple operating system and the App Store offers the best app selection imaginable. Each and every aspect of iOS is curated by Apple, and it shows.
                        Android is more open and it has fewer rules, which means more customization options, but it also means that manufacturers and app makers can willfully ignore Google’s Material
                        design language and do whatever they want, sometimes rendering Android unrecognizable and cluttered. Android is gorgeous, as seen on Google’s Pixel 3 and 3 XL, but you don’t always
                        see it like that.<br><br>
                        Since Apple is the only company that makes iPhones, it also has complete control over software updates. As such, iPhone users always get the most recent and best iOS experience
                        possible. Android users don’t have that luxury. Unless you own a Pixel device — and very few of Android users do — you may have to wait months to get software updates.<br><br>
                        Software updates contain security updates, which keep your phone safe from malware, viruses, and hackers. Android is the most heavily targeted mobile OS, so it is attacked by the
                        worst bugs, like Stagefright and Heartbleed, both of which remain unpatched for millions of Android users. Google now issues a security patch every month, but many manufacturers
                        don’t push them to users in a timely fashion, if ever.<br><br>
                        In contrast, Apple can patch security flaws and send the updated software to all iPhone users immediately. Since most users update their software when prompted, most iOS users
                        are protected from these very real threats. Apple’s iOS also offers full encryption with no compromises.
                    </p>
                    <h2 class="news__subtitle is-uppercase">How we test</h2>
                    <p>
                        A phone is so much more than its spec sheet. Your entire life is stored on it, from your photos and contacts to your music and your favorite apps. For that reason, we take great care
                        when we review phones to recommend only the absolute best out there.<br><br>
                        We use each phone we review as our daily driver for an extended period of time to test out all the features. We do benchmarks, play games, take photos, plunge them in water,
                        and use them until their batteries expire. Then we charge ’em up and do it all over again. We test devices like real people use devices. We’re not in some stuffy lab during obscure
                        tests. We’re running around the cities we live in using these phones just like you use your phones. Then we think about each phone in comparison with its competitors to come to
                        a decision: Should you buy this phone or something else? If the answer is “something else,” we tell you what to buy instead.<br><br>
                        Smartphone innovation has peaked, and the number of radical new features that come out each year is shrinking. As such, when you’re buying a phone, you’re buying a device
                        that will be with you 24/7 for several years. It’s a big choice, and things like apps, a product ecosystem, customer support, and security should be important factors
                        in your buying decision.
                    </p>
                    <p class="news__img-caption">The source: https://www.digitaltrends.com/mobile/best-smartphones/</p>
                </div>
                <div class="sarafan-floor" />
            </div>
        </div>
        <Footer @contactShow="openModal('contact')" />
        <signin v-if="signInShow" @close="closeModal('signIn')" />
        <contact v-if="contactShow" @close="closeModal('contact')" />
    </div>
</template>
<script>
    import Header from '@/components/landing/Header'
    import Footer from '@/components/landing/Footer'
    import signin from '@/components/landing/signin'
    import contact from '@/components/landing/contact'
    import plugin from '@/mixins/plugin'
    export default {
        components: {
            Header, Footer, signin, contact
        },
        mixins: [plugin],
        metaInfo() {
            return {
                title: this.meta.title,
                meta: [
                    { name: 'description', content: this.meta.description },
                    { name: 'keywords', content: this.meta.keywords },
                    { itemprop: 'name', content: (this.meta.schema_name !== '' ? this.meta.schema_name : this.meta.title) },
                    { itemprop: 'description', content: (this.meta.schema_description !== '' ? this.meta.schema_description : this.meta.description) }
                ]
            }
        },
        data() {
            return {
                meta: {
                    title: 'The best smartphones for 2019',
                    description: '',
                    keywords: '',
                    schema_name: '',
                    schema_description: ''
                },
                signInShow: false,
                contactShow: false
            }
        },
        destroyed() {
            document.getElementsByTagName('sarafan-cart')[0].remove()
            if (document.getElementsByTagName('sarafan-cart').length === 1) {
                document.getElementsByTagName('sarafan-cart')[0].remove()
            }
        },
        methods: {
            openModal(name, event = false) {
                if (name === 'signUp') {
                    this.typeAccount = event
                }
                this[name + 'Show'] = true
                document.getElementsByTagName('html')[0].classList.add('is-clipped')
            },
            closeModal(name) {
                this[name + 'Show'] = false
                document.getElementsByTagName('html')[0].classList.remove('is-clipped')
            }
        }
    }
</script>