import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import helpers from '@/mixins/helpers'
import api from '@/api/routes'
import Buefy from 'buefy'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faFacebookF, faLinkedin } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import VueLazyLoadVideo from 'vue-lazyload-video'
import VueCookies from 'vue-cookies'
import Meta from 'vue-meta'
import VueProgressBar from 'vue-progressbar'
import i18n from '@/i18n/index'
import modernizr from 'modernizr' // eslint-disable-line no-unused-vars

Vue.prototype.$api = api
Vue.use(Buefy)
Vue.use(VueLazyLoadVideo)
Vue.use(Meta)
Vue.use(VueProgressBar, {
    color: 'rgb(143, 255, 199)',
    failedColor: 'red',
    height: '10px'
})
Vue.mixin(helpers)
Vue.config.productionTip = false

library.add(faFacebookF, faLinkedin)
Vue.component('FontAwesomeIcon', FontAwesomeIcon)

Vue.use(VueCookies)
VueCookies.config('180d')

const app = new Vue({
    i18n,
    router,
    store,
    mounted: () => document.dispatchEvent(new Event('x-app-rendered')),
    render: h => h(App)
}).$mount('#app')

app.$router.beforeEach((to, from, next) => {
    app.$Progress.start()
    next()
})

app.$router.afterEach(() => {
    app.$Progress.finish()
})