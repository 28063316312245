<template>
    <div class="modal contact-modal is-active">
        <div class="modal-background" />
        <div class="modal-content">
            <div class="box">
                <div v-if="showBlock === 'main'" class="modal-block">
                    <button class="modal-close is-large" aria-label="close" @click="$emit('close')" />
                    <h3 class="modal__title">{{ $t('contact.title') }}</h3>
                    <div :class="`modal__input-block modal__input-block--first${name.mode === 'fill' ? ' fill' : ''}` +
                        `${name.mode === 'focus' ? ' focus' : ''}`">
                        <input ref="name" v-model="name.value" type="text" class="input modal__input"
                               :placeholder="$t('contact.namePlaceholder')" maxlength="40" @blur="checkField('name')"
                               @focus="name.mode = 'focus'">
                        <span class="modal__input__label">{{ $t('contact.nameLabel') }}</span>
                        <span class="modal__input__hint">{{ $t('contact.nameHint') }}</span>
                    </div>
                    <div :class="`modal__input-block${email.mode === 'focus' ? ' focus' : ''}` +
                        `${email.mode === 'correct' ? ' correct' : ''}${email.mode === 'incorrect' ? ' incorrect' : ''}`">
                        <input ref="email" v-model="email.value" type="text" class="input modal__input"
                               :placeholder="$t('contact.emailPlaceholder')" maxlength="40" @blur="checkEmail" @focus="email.mode = 'focus'">
                        <span class="modal__input__label">{{ $t('contact.emailLabel') }}</span>
                        <span v-if="email.mode === 'focus'" class="modal__input__hint">{{ $t('contact.emailHintFocus') }}</span>
                        <span v-if="email.mode === 'correct'" class="modal__input__hint">{{ $t('contact.emailHintCorrect') }}</span>
                        <span v-if="email.mode === 'incorrect'" class="modal__input__hint">
                            {{ $t('contact.emailHintIncorrect') }}
                        </span>
                    </div>
                    <textarea ref="message" v-model="message.value" class="textarea modal__textarea"
                              :placeholder="$t('contact.message')" maxlength="350" />
                    <div ref="checkboxBlock" class="checkbox-block">
                        <input id="checkbox" v-model="checkbox" type="checkbox" class="modal__checkbox__input"
                               @change="$refs.checkboxBlock.classList.remove('error')">
                        <label for="checkbox" class="checkbox modal__checkbox__text">
                            {{ $t('contact.messageCheckbox') }}
                        </label>
                    </div>
                    <button type="button" class="modal__submit" @click="submitForm">{{ $t('contact.send') }}</button>
                </div>
                <div v-if="showBlock === 'success'" class="modal-block">
                    <button class="modal-close is-large" aria-label="close" @click="$emit('close')" />
                    <h3 class="modal__title">{{ $t('contact.successTitle') }}</h3>
                    <p class="modal__text">{{ $t('contact.successDescription') }}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                name: {
                    value: '',
                    mode: 'default'
                },
                email: {
                    value: '',
                    mode: 'default'
                },
                message: {
                    value: '',
                    mode: 'default'
                },
                checkbox: false,
                showBlock: 'main'
            }
        },
        methods: {
            checkField(elem) {
                if (this[elem].value !== '') {
                    this[elem].mode = 'fill'
                } else {
                    this[elem].mode = 'default'
                }
            },
            checkEmail() {
                let re = /\S+@\S+\.\S+/
                this.email.mode = re.test(this.email.value) ? 'correct' : 'incorrect'
            },
            async submitForm() {
                if (!this.checkbox) {
                    this.$buefy.toast.open({
                        message: 'Approval for data processing required',
                        type: 'is-danger'
                    })
                    this.$refs.checkboxBlock.classList.add('error')
                    return false
                }

                if (window.send) {
                    return false
                }
                window.send = true

                if (!this.checkFields()) {
                    window.send = false
                    return false
                }

                let data = {
                    subject: 'Сообщение с сайта sarafan.tech',
                    body: `Страница: Technology<br>` +
                        `Имя: ${this.name.value}<br>` +
                        `Почта: ${this.email.value}<br>` +
                        `Сообщение: ${this.message.value}`
                }
                let res = await this.$api.createRequest(data)
                if (res) {
                    if (res.error) {
                        this.$buefy.toast.open({
                            message: res.error,
                            type: 'is-danger'
                        })
                    } else {
                        this.showBlock = 'success'
                    }
                }

                window.send = false
            },
            checkFields() {
                let reqArr = ['name', 'email', 'message']
                for (let i = 0; i < reqArr.length; i++) {
                    if (this[reqArr[i]].value === '') {
                        this.$buefy.toast.open({
                            message: `Field ${reqArr[i]} required`,
                            type: 'is-danger'
                        })
                        this.$refs[reqArr[i]].focus()
                        return false
                    }
                }

                if (this.email.mode === 'incorrect') {
                    this.$buefy.toast.open({
                        message: 'Please enter a valid e-mail',
                        type: 'is-danger'
                    })
                    return false
                }
                return true
            }
        }
    }
</script>