<template>
    <div class="modal sign-in-modal is-active">
        <div class="modal-background" />
        <div class="modal-content">
            <div class="box">
                <div v-if="showBlock === 'sign-in'" class="modal-block">
                    <button class="modal-close is-large" aria-label="close" @click="$emit('close')" />
                    <h3 class="modal__title">Sign in</h3>
                    <div :class="`modal__input-block modal__input-block--first${login.mode === 'fill' ? ' fill' : ''}` +
                        `${login.mode === 'focus' ? ' focus' : ''}`">
                        <input v-model="login.value" type="text" class="input modal__input" placeholder="Login"
                               @blur="checkField('login')" @focus="login.mode = 'focus'">
                        <span class="modal__input__label">Login</span>
                        <span class="modal__input__hint">Please enter login</span>
                    </div>
                    <div :class="`modal__input-block${password.mode === 'fill' ? ' fill' : ''}` +
                        `${password.mode === 'focus' ? ' focus' : ''}`">
                        <input v-model="password.value" type="password" class="input modal__input"
                               placeholder="Password" @blur="checkField('password')"
                               @focus="password.mode = 'focus'">
                        <span class="modal__input__label">Password</span>
                        <span class="modal__input__hint">Please enter password</span>
                    </div>
                    <div class="modal__buttons-block">
                        <input id="remember" type="checkbox" class="modal__checkbox__input">
                        <label for="remember" class="checkbox modal__checkbox__text">Remember me</label>
                        <button type="button" class="sign-in-modal__forgot" @click="showBlock = 'forgot'">
                            Forgot password
                        </button>
                    </div>
                    <button type="button" class="modal__submit">Send</button>
                </div>
                <div v-if="showBlock === 'forgot'" class="modal-block forgot">
                    <button class="modal-close is-large" aria-label="close" @click="$emit('close')" />
                    <h3 class="modal__title">Find your email</h3>
                    <p class="modal__text">Enter your recovery email</p>
                    <div :class="`modal__input-block modal__input-block--first` +
                        `${forgotEmail.mode === 'fill' ? ' fill' : ''}${forgotEmail.mode === 'focus' ? ' focus' : ''}`">
                        <input v-model="forgotEmail.value" type="password" class="input modal__input"
                               placeholder="E-mail for refresh password" @blur="checkField('forgotEmail')"
                               @focus="forgotEmail = 'focus'">
                        <span class="modal__input__label">E-mail</span>
                        <span class="modal__input__hint">Please enter e-mail</span>
                    </div>
                    <button type="button" class="modal__submit">Send</button>
                    <button type="button" class="modal__back" @click="showBlock = 'sign-in'">Back to login</button>
                </div>
                <div v-if="showBlock === 'forgot-success'" class="modal-block forgot-success">
                    <button class="modal-close is-large" aria-label="close" @click="$emit('close')" />
                    <h3 class="modal__title">Thank you</h3>
                    <p class="modal__text">Password sent to the specified mail</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                showBlock: 'sign-in',
                login: {
                    value: '',
                    mode: 'default'
                },
                password: {
                    value: '',
                    mode: 'default'
                },
                forgotEmail: {
                    value: '',
                    mode: 'default'
                }
            }
        },
        methods: {
            checkField(elem) {
                if (this[elem].value !== '') {
                    this[elem].mode = 'fill'
                } else {
                    this[elem].mode = 'default'
                }
            }
        }
    }
</script>