import Vue from 'vue'
import VueI18n from 'vue-i18n'
import ru from './ru.json'
import en from './en.json'

Vue.use(VueI18n)

const messages = { ru, en }

export default new VueI18n({
    locale: 'en',
    fallbackLocale: 'en',
    messages
})
