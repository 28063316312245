<template>
    <section class="first-screen">
        <Header @signInShow="$emit('signInShow', $event)" />
        <div class="container">
            <div class="main-block columns">
                <div class="main-block__left column is-12 is-6-fullhd">
                    <div class="titles">
                        <h1 class="title">Sarafan for advertisers</h1>
                        <p class="subtitle">
                            Use interactive publishers` content  to communicate with your target audience
                            and boost sales
                        </p>
                        <button type="button" class="apply-button" @click="$emit('signUpShow', true)">
                            Sign up
                        </button>
                    </div>
                </div>
                <div class="main-block__right column is-12 is-6-fullhd" @mouseenter="stopTimer"
                     @mouseleave="resumeTimer">
                    <div v-show="activeImage === 1" :class="'image-block l-0-desktop' + (closeImage ? ' close' : '')">
                        <picture v-if="imageShow">
                            <source type="image/webp" srcset="/images/object-4.webp">
                            <img src="/images/object-4.png" class="image">
                        </picture>
                        <div v-if="rects[0]" class="rect rect-1-1" />
                        <div v-if="rects[1]" class="rect rect-1-2" />
                        <div v-if="rects[2]" class="rect rect-1-3" />
                        <div class="offers">
                            <div v-if="offers[0]" ref="offer-1-1" class="offer offer-1-1" @click="closeOffer('1-1')">
                                <div class="offer__image">
                                    <img src="/images/offer-4-1.png">
                                </div>
                                <div class="offer__text">
                                    <div class="offer__text__name">T-Shirt</div>
                                    <div class="offer__text__brand">Sela</div>
                                </div>
                            </div>
                            <div v-if="offers[1]" ref="offer-1-2" class="offer offer-1-2" @click="closeOffer('1-2')">
                                <div class="offer__image">
                                    <img src="/images/offer-4-2.png">
                                </div>
                                <div class="offer__text">
                                    <div class="offer__text__name">Glasses</div>
                                    <div class="offer__text__brand">Ray-Ban®</div>
                                </div>
                            </div>
                            <div v-if="offers[2]" ref="offer-1-3" class="offer offer-1-3" @click="closeOffer('1-3')">
                                <div class="offer__image">
                                    <img src="/images/offer-4-3.png">
                                </div>
                                <div class="offer__text">
                                    <div class="offer__text__name">Clock</div>
                                    <div class="offer__text__brand">Diesel</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-show="activeImage === 2" :class="'image-block l-0-desktop' + (closeImage ? ' close' : '')">
                        <img v-if="imageShow" src="/images/object-5.png" class="image">
                        <div v-if="rects[0]" class="rect rect-2-1" />
                        <div v-if="rects[1]" class="rect rect-2-2" />
                        <!--<div class="rect rect-2-3" v-if="rects[2]"></div>-->
                        <div v-if="rects[2]" class="rect rect-2-4" />
                        <div class="offers">
                            <div v-if="offers[0]" ref="offer-2-1" class="offer offer-2-1" @click="closeOffer('2-1')">
                                <div class="offer__image">
                                    <img src="/images/offer-5-1.png">
                                </div>
                                <div class="offer__text">
                                    <div class="offer__text__name">Coat</div>
                                    <div class="offer__text__brand">Incity</div>
                                </div>
                            </div>
                            <div v-if="offers[1]" ref="offer-2-2" class="offer offer-2-2" @click="closeOffer('2-2')">
                                <div class="offer__image">
                                    <img src="/images/offer-5-2.png">
                                </div>
                                <div class="offer__text">
                                    <div class="offer__text__name">Coat</div>
                                    <div class="offer__text__brand">Vivaldi</div>
                                </div>
                            </div>
                            <div v-if="offers[2]" ref="offer-2-3" class="offer offer-2-4" @click="closeOffer('2-3')">
                                <div class="offer__image">
                                    <img src="/images/offer-5-4.png">
                                </div>
                                <div class="offer__text">
                                    <div class="offer__text__name">Bag</div>
                                    <div class="offer__text__brand">Antan</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-show="activeImage === 3" :class="'image-block l-0-desktop' + (closeImage ? ' close' : '')">
                        <img v-if="imageShow" src="/images/object-6.png" class="image">
                        <div v-if="rects[0]" class="rect rect-3-1" />
                        <div v-if="rects[1]" class="rect rect-3-2" />
                        <!--<div class="rect rect-3-3" v-if="rects[2]"></div>-->
                        <div class="offers">
                            <div v-if="offers[0]" ref="offer-3-1" class="offer offer-3-1" @click="closeOffer('3-1')">
                                <div class="offer__image">
                                    <img src="/images/offer-6-1.png">
                                </div>
                                <div class="offer__text">
                                    <div class="offer__text__name">Blouse</div>
                                    <div class="offer__text__brand">Hellen Barretta</div>
                                </div>
                            </div>
                            <div v-if="offers[1]" ref="offer-3-2" class="offer offer-3-2" @click="closeOffer('3-2')">
                                <div class="offer__image">
                                    <img src="/images/offer-6-2.png">
                                </div>
                                <div class="offer__text">
                                    <div class="offer__text__name">Jeans</div>
                                    <div class="offer__text__brand">Stradivarius</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import firstScreenMixin from '@/mixins/firstScreenMixin'
    import Header from '@/components/landing/main/Header.vue'

    export default {
        components: {
            Header
        },
        mixins: [firstScreenMixin],
        data() {
            return {
                offersEl: [3, 3, 2]
            }
        }
    }
</script>