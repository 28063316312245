<template>
    <section class="index-first-screen">
        <Header />
        <div class="container">
            <div class="index-first-screen__center">
                <h1 class="index-first-screen__title">{{ $t('index.firstScreen.title') }}</h1>
                <button type="button" class="index-first-screen__button"
                        @click="$clickLink('#request', '/')">
                    {{ $t('index.firstScreen.button') }}
                </button>
                <p class="index-first-screen__description">{{ $t('index.firstScreen.text') }}</p>
            </div>
            <div class="index-first-screen__line index-first-screen__line--first" />
            <div class="index-first-screen__bottom">
                <div class="index-first-screen__bottom-left">
                    <h2 class="index-first-screen__subtitle">{{ $t('index.firstScreen.bottomTitle') }}</h2>
                </div>
                <div class="index-first-screen__bottom-right">
                    <p class="index-first-screen__text">{{ $t('index.firstScreen.bottomDescription') }}</p>
                    <router-link to="/technology" class="index-first-screen__link">
                        {{ $t('index.firstScreen.learnMore') }}
                        <img src="/images/index-arrow-right.svg" alt="">
                    </router-link>
                    <div class="index-first-screen__line" />
                    <div class="index-first-screen__counts">
                        <div class="index-first-screen__count">
                            <div class="index-first-screen__count-number">+45%</div>
                            <div class="index-first-screen__count-text">{{ $t('index.firstScreen.stat1') }}</div>
                        </div>
                        <div class="index-first-screen__count">
                            <div class="index-first-screen__count-number">+32%</div>
                            <div class="index-first-screen__count-text">{{ $t('index.firstScreen.stat2') }}</div>
                        </div>
                        <div class="index-first-screen__count">
                            <div class="index-first-screen__count-number">29%</div>
                            <div class="index-first-screen__count-text">{{ $t('index.firstScreen.stat3') }}</div>
                        </div>
                        <div class="index-first-screen__count">
                            <div class="index-first-screen__count-number">6M+</div>
                            <div class="index-first-screen__count-text">{{ $t('index.firstScreen.stat4') }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import Header from '@/components/landing/main/Header.vue'
    export default {
        components: {
            Header
        }
    }
</script>