<template>
    <section class="products">
        <div class="container">
            <h2 class="title">Products</h2>
            <div class="content">
                <div v-if="!tabsMobile" class="buttons">
                    <button type="button" :class="'tab-button' + (activeTab === 1 ? ' active' : '')"
                            @click="activeTab = 1">
                        Video
                    </button>
                    <button type="button" :class="'tab-button' + (activeTab === 2 ? ' active' : '')"
                            @click="activeTab = 2">
                        In-image
                    </button>
                    <button type="button" :class="'tab-button' + (activeTab === 3 ? ' active' : '')"
                            @click="activeTab = 3">
                        Get this look
                    </button>
                </div>
                <div class="tabs-block">
                    <div v-show="activeTab === 1 || tabsMobile" class="tab columns">
                        <div class="tab__left column">
                            <picture>
                                <source type="image/webp" srcset="/images/player-advert.webp">
                                <img src="/images/player-advert.png">
                            </picture>
                        </div>
                        <div class="tab__right column">
                            <h3 class="tab__right__title">Shoppable video</h3>
                            <p class="tab__right__text">
                                Your products will be shown in the sidebar of the video and can easily be complemented
                                with additional marketing banners.
                                Sarafan.AI works with all video formats including videos on hosting services and streams
                            </p>
                        </div>
                    </div>
                    <div v-show="activeTab === 2 || tabsMobile" class="tab columns">
                        <div class="tab__left column">
                            <div class="image-block">
                                <picture>
                                    <source type="image/webp" srcset="/images/in-image-advert.webp">
                                    <img src="/images/in-image-advert.png">
                                </picture>
                                <div v-if="bannerShow && (activeTab === 2 || tabsMobile)" class="banner">
                                    <div class="banner__close" @click="bannerShow = false"><span>x</span></div>
                                    <div class="banner__powered">Powered by sarafan</div>
                                    <div class="banner__ads">
                                        <div class="banner__ads__icon"><img src="/images/info.svg"></div>
                                        <span class="banner__ads__text">Ads</span>
                                    </div>
                                    <div class="banner__content">
                                        <div class="banner__content__left">
                                            <div class="banner__content__title">AUDI RS5</div>
                                            <div class="banner__content__button"><div>Discover more</div></div>
                                        </div>
                                        <div class="banner__content__right">
                                            <div class="banner__content__image">
                                                <img src="/images/banner-image-advert.png">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="tab__right column">
                            <h3 class="tab__right__title">In-image</h3>
                            <p class="tab__right__text">
                                Your ad slot will be embedded  into an image with relevant content.
                                Key verticals are analysed: logos, brands, brand ambassadors, sights,
                                cities, holidays, and events
                            </p>
                        </div>
                    </div>
                    <div v-show="activeTab === 3 || tabsMobile" class="tab columns">
                        <div class="tab__left column">
                            <div class="look-block">
                                <div class="look-block__top">
                                    <div class="circle red" />
                                    <div class="circle yellow" />
                                    <div class="circle green" />
                                </div>
                                <div class="look-block__content">
                                    <div class="columns">
                                        <div class="column column-image">
                                            <div class="image-block">
                                                <img src="/images/look.png">
                                                <div :class="`offer-price offer-price-1${offerPriceShow ? ' fadeIn' : ''}`">
                                                    <div class="offer__text">
                                                        <div class="offer__text__brand">Monokel</div>
                                                        <div class="offer__text__name">$101.89</div>
                                                    </div>
                                                </div>
                                                <div :class="`offer-price offer-price-2${offerPriceShow ? ' fadeIn' : ''}`">
                                                    <div class="offer__text">
                                                        <div class="offer__text__brand">Edward</div>
                                                        <div class="offer__text__name">$28.24</div>
                                                    </div>
                                                </div>
                                                <div :class="`offer-price offer-price-3${offerPriceShow ? ' fadeIn' : ''}`">
                                                    <div class="offer__text">
                                                        <div class="offer__text__brand">Pull&Bear</div>
                                                        <div class="offer__text__name">$36.09</div>
                                                    </div>
                                                </div>
                                                <button type="button" class="look-block__content__button"
                                                        @click="offerPriceShow = true">
                                                    <img src="/images/cart.svg">Get this look
                                                </button>
                                            </div>
                                        </div>
                                        <div class="column column-text">
                                            <h4 class="look-block__content__title">
                                                Why long T's and high wasted jeans go together
                                            </h4>
                                            <p class="look-block__content__text">
                                                Fashion is all about expressing yourself in unique ways.
                                                Different traits of character demand different colors
                                                and we know how to combine!
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="tab__right column">
                            <h3 class="tab__right__title">Get this look</h3>
                            <p class="tab__right__text">
                                The widget with your products will expand after clicking the "Get this look" option
                                each product can be pinned regardless of photo or video format.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        data() {
            return {
                activeTab: 1,
                tabsMobile: false,
                offerPriceShow: false,
                bannerShow: true
            }
        },
        watch: {
            activeTab() {
                if (this.activeTab === 2) this.bannerShow = true
            }
        },
        created() {
            window.addEventListener('resize', this.initTabsShow)
            this.initTabsShow()
        },
        destroyed() {
            window.removeEventListener('resize', this.initTabsShow)
        },
        methods: {
            initTabsShow() {
                this.tabsMobile = window.innerWidth <= 580
            }
        }
    }
</script>