<template>
    <section class="team-images">
        <div class="container">
            <div class="row">
                <div class="team-images__block">
                    <div class="team-images__block-top">
                        <div class="image-block">
                            <img ref="image-1" src="/images/team-1.png">
                        </div>
                        <div class="image-block">
                            <img ref="image-2" src="/images/team-2.png">
                        </div>
                        <div class="image-block not-mobile">
                            <img src="/images/team-3.png">
                        </div>
                        <div class="image-block not-mobile">
                            <img src="/images/team-4.png">
                        </div>
                        <div class="image-block only-desktop">
                            <img src="/images/team-5.png">
                        </div>
                        <div class="image-block only-desktop">
                            <img src="/images/team-6.png">
                        </div>
                    </div>
                    <div class="team-images__block-bottom">
                        <div class="image-block">
                            <img ref="image-3" src="/images/team-7.png">
                        </div>
                        <div class="image-block">
                            <img ref="image-4" src="/images/team-8.png">
                        </div>
                        <div class="image-block not-mobile">
                            <img src="/images/team-9.png">
                        </div>
                        <div class="image-block not-mobile">
                            <img src="/images/team-10.png">
                        </div>
                        <div class="image-block only-desktop">
                            <img src="/images/team-11.png">
                        </div>
                        <div class="image-block only-desktop">
                            <img src="/images/team-12.png">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        data() {
            return {
                images: [1, 2, 7, 8],
                imagesRange: [1, 12],
                blocksRange: [1, 4],
                lastBlock: 0
            }
        },
        created() {
            this.animateImages()
        },
        methods: {
            animateImages() {
                if (window.innerWidth <= 860) {
                    setInterval(() => {
                        let block, image, find
                        do {
                            block = this.getRandomNumber(this.blocksRange)
                            find = (this.lastBlock === block)
                        } while (find)
                        this.lastBlock = block
                        do {
                            image = this.getRandomNumber(this.imagesRange)
                            find = false
                            this.images.forEach(el => {
                                if (image === el) find = true
                            })
                        } while (find)
                        this.images[block - 1] = image
                        this.$refs['image-' + block].classList.add('fadeOut')
                        setTimeout(() => {
                            this.$refs['image-' + block].setAttribute('src', '/images/team-' + image + '.png')
                            this.$refs['image-' + block].classList.add('fadeIn')
                        }, 500)
                        setTimeout(() => {
                            this.$refs['image-' + block].classList.remove('fadeIn')
                            this.$refs['image-' + block].classList.remove('fadeOut')
                        }, 1000)
                    }, 3000)
                }
            },
            getRandomNumber(range) {
                return Math.floor(Math.random() * (range[1] - range[0] + 1) + range[0])
            }
        }
    }
</script>