<template>
    <div class="modal send-cv-modal is-active">
        <div class="modal-background" />
        <div class="modal-content">
            <div class="box">
                <div v-if="showBlock === 'main'" class="modal-block">
                    <button class="modal-close is-large" aria-label="close" @click="$emit('close')" />
                    <h3 class="modal__title">Tell us about yourself</h3>
                    <div :class="`modal__input-block modal__input-block--first${name.mode === 'fill' ? ' fill' : ''}` +
                        `${name.mode === 'focus' ? ' focus' : ''}`">
                        <input ref="name" v-model.trim="name.value" type="text" class="input modal__input"
                               placeholder="Your name" maxlength="40" @blur="checkField('name')"
                               @focus="name.mode = 'focus'">
                        <span class="modal__input__label">Name</span>
                        <span class="modal__input__hint">Please enter your name</span>
                    </div>
                    <div :class="`modal__input-block${surname.mode === 'fill' ? ' fill' : ''}` +
                        `${surname.mode === 'focus' ? ' focus' : ''}`">
                        <input ref="surname" v-model.trim="surname.value" type="text" class="input modal__input"
                               placeholder="Your surname" maxlength="40" @blur="checkField('surname')"
                               @focus="surname.mode = 'focus'">
                        <span class="modal__input__label">Surname</span>
                        <span class="modal__input__hint">Please enter your surname</span>
                    </div>
                    <div :class="`modal__input-block${email.mode === 'focus' ? ' focus' : ''}` +
                        `${email.mode === 'correct' ? ' correct' : ''}${email.mode === 'incorrect' ? ' incorrect' : ''}`">
                        <input ref="email" v-model.trim="email.value" type="text" class="input modal__input"
                               placeholder="Your e-mail" maxlength="40" @blur="checkEmail"
                               @focus="email.mode = 'focus'">
                        <span class="modal__input__label">E-mail</span>
                        <span v-if="email.mode === 'focus'" class="modal__input__hint">Please enter your e-mail</span>
                        <span v-if="email.mode === 'correct'" class="modal__input__hint">Correct e-mail</span>
                        <span v-if="email.mode === 'incorrect'" class="modal__input__hint">
                            Please enter a valid e-mail
                        </span>
                    </div>
                    <div :class="`modal__input-block${phone.mode === 'focus' ? ' focus' : ''}` +
                        `${phone.mode === 'correct' ? ' correct' : ''}${phone.mode === 'incorrect' ? ' incorrect' : ''}`">
                        <input ref="phone" v-model.trim="phone.value" type="tel" class="input modal__input"
                               placeholder="Your phone number" maxlength="30" @blur="checkPhone"
                               @focus="phone.mode = 'focus'">
                        <span class="modal__input__label">Phone number</span>
                        <span v-if="phone.mode === 'focus'" class="modal__input__hint">
                            Please enter your phone number
                        </span>
                        <span v-if="phone.mode === 'correct'" class="modal__input__hint">Correct phone</span>
                        <span v-if="phone.mode === 'incorrect'" class="modal__input__hint">
                            Please enter a valid phone
                        </span>
                    </div>
                    <textarea v-model.trim="message.value" class="textarea modal__textarea" placeholder="Your message"
                              maxlength="350" />
                    <label :class="'send-cv__block' + (CV.mode === 'fill' ? ' fill' : '')">
                        <span v-if="CV.mode === 'fill'" class="send-cv__label">CV</span>
                        <span v-html="(fileName === '' ? 'Attach CV' : fileName)" />
                        <input type="file" accept=".doc, .docx, .pdf, .rtf" class="send-cv__file"
                               @change="processFile($event)">
                    </label>
                    <p class="modal__text">Valid formats: .doc, .docx, .pdf, .rtf</p>
                    <div ref="checkboxBlock" class="checkbox-block">
                        <input id="checkbox" v-model="checkbox" type="checkbox" class="modal__checkbox__input"
                               @change="$refs.checkboxBlock.classList.remove('error')">
                        <label for="checkbox" class="checkbox modal__checkbox__text">
                            I consent to the processing of my personal data
                        </label>
                    </div>
                    <button type="button" class="modal__submit" @click="submitForm">Send</button>
                </div>
                <div v-if="showBlock === 'success'" class="modal-block">
                    <button class="modal-close is-large" aria-label="close" @click="$emit('close')" />
                    <h3 class="modal__title">Thank you</h3>
                    <p class="modal__text">Your request has been sent</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                name: {
                    value: '',
                    mode: 'default'
                },
                surname: {
                    value: '',
                    mode: 'default'
                },
                email: {
                    value: '',
                    mode: 'default'
                },
                phone: {
                    value: '',
                    mode: 'default'
                },
                message: {
                    value: '',
                    mode: 'default'
                },
                CV: {
                    value: '',
                    mode: 'default'
                },
                fileName: '',
                checkbox: false,
                showBlock: 'main'
            }
        },
        watch: {
            'phone.value'() {
                this.enterPhone()
            }
        },
        methods: {
            checkField(elem) {
                if (this[elem].value !== '') {
                    this[elem].mode = 'fill'
                } else {
                    this[elem].mode = 'default'
                }
            },
            enterPhone() {
                this.phone.value = this.phone.value.replace(/[^0-9+]/, '')
            },
            checkPhone() {
                let re = /^[0-9+]{1,}[0-9-]{9,15}$/
                this.phone.mode = re.test(this.phone.value) ? 'correct' : 'incorrect'
            },
            checkEmail() {
                let re = /\S+@\S+\.\S+/
                this.email.mode = re.test(this.email.value) ? 'correct' : 'incorrect'
            },
            processFile(event) {
                let formats = ['doc', 'docx', 'pdf', 'rtf']
                let file = event.target.files[0]
                if (!file) {
                    this.CV.value = ''
                    this.CV.mode = 'default'
                    this.fileName = ''
                    return false
                }
                let fileSize = file.size
                if (fileSize > 2097152) {
                    this.$buefy.toast.open({
                        message: 'File size should be no more than 2MB',
                        type: 'is-danger'
                    })
                    this.CV.value = ''
                    this.CV.mode = 'default'
                    this.fileName = ''
                    return false
                }
                let fileName = file.name
                let fileFormat = fileName.split('.').pop()
                let find = false
                formats.forEach(el => {
                    if (el === fileFormat) find = true
                })
                if (!find) {
                    this.$buefy.toast.open({
                        message: 'Incorrect type file',
                        type: 'is-danger'
                    })
                    this.CV.value = ''
                    this.CV.mode = 'default'
                    this.fileName = ''
                    return false
                }
                this.CV.value = event.target.files[0]
                this.CV.mode = 'fill'
                this.fileName = fileName
            },
            async submitForm() {
                if (!this.checkbox) {
                    this.$buefy.toast.open({
                        message: 'Approval for data processing required',
                        type: 'is-danger'
                    })
                    this.$refs.checkboxBlock.classList.add('error')
                    return false
                }

                if (window.send) {
                    return false
                }
                window.send = true

                if (!this.checkFields()) {
                    window.send = false
                    return false
                }

                let formData = new FormData()
                formData.append('modal', 'CV')
                formData.append('name', this.name.value)
                formData.append('surname', this.surname.value)
                formData.append('phone', this.phone.value)
                formData.append('email', this.email.value)
                formData.append('message', this.message.value)
                formData.append('file', this.CV.value)
                let res = await this.$api.createRequest(formData)
                if (res) {
                    if (res.error) {
                        this.$buefy.toast.open({
                            message: res.error,
                            type: 'is-danger'
                        })
                    } else {
                        this.showBlock = 'success'
                    }
                }

                window.send = false
            },
            checkFields() {
                let reqArr = ['name', 'surname', 'email', 'phone']
                for (let i = 0; i < reqArr.length; i++) {
                    if (this[reqArr[i]].value === '') {
                        this.$buefy.toast.open({
                            message: 'Field ' + reqArr[i] + ' required',
                            type: 'is-danger'
                        })
                        this.$refs[reqArr[i]].focus()
                        return false
                    }
                }

                if (this.email.mode === 'incorrect') {
                    this.$buefy.toast.open({
                        message: 'Please enter a valid e-mail',
                        type: 'is-danger'
                    })
                    return false
                }
                return true
            }
        }
    }
</script>