<script>
    export default {
        mounted() {
            if (!document.getElementsByClassName('sarafanscr').length) {
                const videoJS = document.createElement('script')
                videoJS.setAttribute('src', 'https://vjs.zencdn.net/7.3.0/video.js')
                document.body.appendChild(videoJS)

                let videoStyle = document.createElement('link')
                videoStyle.type = 'text/css'
                videoStyle.rel = 'stylesheet'
                videoStyle.href = 'https://vjs.zencdn.net/7.3.0/video-js.css'
                document.head.appendChild(videoStyle)

                let videoStyle2 = document.createElement('link')
                videoStyle2.type = 'text/css'
                videoStyle2.rel = 'stylesheet'
                videoStyle2.href = '/style/video-js-style.css'
                document.head.appendChild(videoStyle2)

                const start = new Function('var sarafan = new Sarafan({}); sarafan.start();')

                let sarafanStyle = document.createElement('link')
                sarafanStyle.type = 'text/css'
                sarafanStyle.rel = 'stylesheet'
                sarafanStyle.href = '/widget/dev/sarafan.min.css'
                document.head.appendChild(sarafanStyle)

                let sarafanScr = document.createElement('script')
                document.body.appendChild(sarafanScr)

                const wcLoader = document.createElement('script')
                wcLoader.setAttribute('src', 'https://unpkg.com/@webcomponents/webcomponentsjs@2.3.0/webcomponents-loader.js')
                document.body.appendChild(wcLoader)

                sarafanScr.onload = () => {
                    let sarafanCart = document.createElement('script')
                    document.body.appendChild(sarafanCart)
                    sarafanCart.onload = () => start()
                    sarafanCart.setAttribute('src', '/cart/master/sarafan.cart.min.js')
                }

                sarafanScr.setAttribute('src', '/widget/dev/sarafan.min.js')
                sarafanScr.setAttribute('class', 'sarafanscr')
            } else {
                const start = new Function('var sarafan = new Sarafan({}); sarafan.start();')
                start()
            }
        }
    }
</script>