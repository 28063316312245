<template>
    <div class="video-page">
        <firstScreen @modalShow="openModal" />
        <TextBlock />
        <videoBlock1 />
        <videoBlock3 />
        <videoBlock2 />
        <brands />
        <Footer @modalShow="openModal" />
        <requestDemo v-if="modalShow" @close="closeModal" />
    </div>
</template>
<script>
    import firstScreen from '@/components/promo/video/firstScreen'
    import TextBlock from '@/components/promo/video/TextBlock'
    import videoBlock1 from '@/components/promo/video/videoBlock1'
    import videoBlock2 from '@/components/promo/video/videoBlock2'
    import videoBlock3 from '@/components/promo/video/videoBlock3'
    import brands from '@/components/promo/video/brands'
    import Footer from '@/components/promo/video/Footer'
    import requestDemo from '@/components/promo/video/requestDemo'
    export default {
        metaInfo() {
            return {
                title: this.meta.title,
                meta: [
                    { name: 'description', content: this.meta.description },
                    { name: 'keywords', content: this.meta.keywords },
                    { itemprop: 'name', content: (this.meta.schema_name !== '' ? this.meta.schema_name : this.meta.title) },
                    { itemprop: 'description', content: (this.meta.schema_description !== '' ? this.meta.schema_description : this.meta.description) }
                ]
            }
        },
        components: {
            firstScreen, TextBlock, videoBlock1, videoBlock2, videoBlock3, brands, Footer, requestDemo
        },
        data() {
            return {
                meta: {
                    title: 'Video - Sarafan Technology',
                    description: '',
                    keywords: '',
                    schema_name: '',
                    schema_description: ''
                },
                modalShow: false
            }
        },
        methods: {
            openModal() {
                this.modalShow = true
                document.getElementsByTagName('html')[0].classList.add('is-clipped')
            },
            closeModal() {
                this.modalShow = false
                document.getElementsByTagName('html')[0].classList.remove('is-clipped')
            }
        }
    }
</script>