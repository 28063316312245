<script>
    export default {
        data() {
            return {
                activeImage: 1,
                imageShow: false,
                rects: [],
                offers: [],
                timer: 10000,
                timeout: {},
                elements: 0,
                maxEl: 0,
                activeAnimate: false,
                closeImage: false
            }
        },
        mounted() {
            if (!this.offersEl) {
                console.error('Required offersEl')
                return false
            }
            this.initRectsOffers()
            this.initAnimations()
            this.startTimer()
        },
        methods: {
            initRectsOffers() {
                this.offersEl.forEach(el => {
                    if (el > this.maxEl) this.maxEl = el
                })
                for (let i = 0; i < this.maxEl; i++) {
                    this.$set(this.rects, i, false)
                    this.$set(this.offers, i, false)
                }
            },
            initAnimations() {
                this.activeAnimate = true
                let timer = 500
                if (window.innerWidth > 580) {
                    setTimeout(() => {
                        this.imageShow = true
                    }, timer)
                } else {
                    this.imageShow = true
                }
                setTimeout(() => {
                    if (window.innerWidth > 580) {
                        for (let i = 0; i < this.offersEl[this.activeImage - 1]; i++) {
                            timer += 350
                            setTimeout(() => {
                                this.$set(this.rects, i, true)
                            }, timer)
                        }
                        for (let i = 0; i < this.offersEl[this.activeImage - 1]; i++) {
                            timer += 350
                            setTimeout(() => {
                                this.$set(this.offers, i, true)
                            }, timer)
                        }
                    } else {
                        for (let i = 0; i < this.offersEl[this.activeImage - 1]; i++) {
                            this.$set(this.rects, i, true)
                        }
                        for (let i = 0; i < this.offersEl[this.activeImage - 1]; i++) {
                            this.$set(this.offers, i, true)
                        }
                    }
                    this.elements = this.offersEl[this.activeImage - 1]
                    timer += 3000
                    setTimeout(() => {
                        this.activeAnimate = false
                    }, timer)
                }, 1000)
            },
            initTimer() {
                this.closeImage = true
                let time = window.innerWidth > 580 ? 500 : 250
                setTimeout(() => {
                    this.imageShow = false
                    for (let i = 0; i < this.maxEl; i++) {
                        this.$set(this.rects, i, false)
                        this.$set(this.offers, i, false)
                    }
                    if (this.activeImage === this.offersEl.length) {
                        this.activeImage = 1
                    } else {
                        this.activeImage++
                    }
                    this.closeImage = false
                    this.initAnimations()
                }, time)
            },
            startTimer() {
                this.timeout = setTimeout(() => {
                    if (this.activeAnimate) {
                        this.timer = 1000
                        this.startTimer()
                    } else {
                        this.initTimer()
                        this.timer = 10000
                        this.startTimer()
                    }
                }, this.timer)
            },
            stopTimer() {
                clearTimeout(this.timeout)
            },
            resumeTimer() {
                this.timer = 4500
                this.startTimer()
            },
            closeOffer(el) {
                if (window.innerWidth > 580) {
                    let $ref = this.$refs['offer-' + el]
                    $ref.classList.add('close-el')
                    this.elements--
                    setTimeout(() => {
                        if (!this.elements) this.initTimer()
                    }, 400)
                }
            }
        }
    }
</script>