<template>
    <div class="longread-page">
        <div class="container">
            <Header @signInShow="openModal('signIn')" />
            <div class="news">
                <div class="news__top">
                    <div class="news__date">September 21, 2017</div>
                    <h1 class="news__title">11 Fall Date-Night Outfit Ideas That Feel Fresh</h1>
                </div>
                <div class="news__content">
                    <img src="/images/longread4-1-2x.png" class="sarafan">
                    <p class="news__img-caption">Photo by Christian Vierig</p>
                    <p>
                        Have plans for a big night out sometime soon? If you need an outfit that reflects your mood, is on-trend for fall, and feels totally fresh, look no further than the 11 fashionable
                        street-style stars in this slideshow to get inspired: From romantic patterns to edgy leather staples, there are tons of ways to get dressed up for date night that go way beyond
                        the tried-and-true LBD. (We do love a good LBD, but there are so many great new styles coming up this autumn—why not step outside the box?) Check out the outfit combinations
                        worth trying this fall, ahead.
                    </p>
                    <h2 class="news__subtitle">Fall Date-Night Outfit Ideas: The Romantic</h2>
                    <p>Dress down a feminine skirt by tucking in dark, high-neck sweater and slipping on a boxy denim jacket.</p>
                    <img src="/images/longread4-2-2x.png" class="sarafan">
                    <p class="news__img-caption">Photo by Christian Vierig</p>
                    <h2 class="news__subtitle">Fall Date-Night Outfit Ideas: The Romantic</h2>
                    <p>Play with proportions by belting a cardigan over an airy, ankle-length skirt.</p>
                    <img src="/images/longread4-3-2x.png" class="sarafan">
                    <p class="news__img-caption">Photo by Daniel Zuchnik</p>
                    <h2 class="news__subtitle">Fall Date-Night Outfit Ideas: The Romantic</h2>
                    <p>Take your summery LWD into fall by updating the silhouette: Try a long-sleeved, button-up style with rounded shoulders, and wear it with strappy or translucent heels.</p>
                    <img src="/images/longread4-4-2x.png" class="sarafan">
                    <p class="news__img-caption">Photo by Christian Vierig</p>
                    <h2 class="news__subtitle">Fall Date-Night Outfit Ideas: The Unexpected Edge</h2>
                    <p>In lieu of a denim tuxedo, try matching your moto jacket to a leather mini and pair it with a muted top. Finish the look off with ankle boots, obviously.</p>
                    <img src="/images/longread4-5.jpg" class="sarafan">
                    <p class="news__img-caption">Photo by Christian Vierig</p>
                    <h2 class="news__subtitle">Fall Date-Night Outfit Ideas: The Unexpected Edge</h2>
                    <p>Be bold with a pair of high-waisted, wide-leg pants. Try them on for size with a long-sleeved, zip-up crop top.</p>
                    <img src="/images/longread4-6-2x.png" class="sarafan">
                    <p class="news__img-caption">Photo by Mireya Acierto</p>
                    <h2 class="news__subtitle">Fall Date-Night Outfit Ideas: The Unexpected Edge</h2>
                    <p>Instead of the traditional jeans-plus-leather moto, turn the outfit combination on its head and go for leather pixie pants and a denim jacket.</p>
                    <img src="/images/longread4-7-2x.png" class="sarafan">
                    <p class="news__img-caption">Photo by Christian Vierig</p>
                    <h2 class="news__subtitle">Fall Date-Night Outfit Ideas: The Unexpected Edge</h2>
                    <p>Or, you can trade in your go-to moto for a souped-up version, like this one, which features a delicate floral design and a cropped fit.</p>
                    <img src="/images/longread4-8-2x.png" class="sarafan">
                    <p class="news__img-caption">Photo by Christian Vierig</p>
                    <h2 class="news__subtitle">Fall Date-Night Outfit Ideas: From Office to Evening</h2>
                    <p>Long-sleeved mini dresses are the perfect answer to transitional dressing—they're a throw-on-and-go outfit that you don't have to think about when a last-minute weeknight date comes up.</p>
                    <img src="/images/longread4-9-2x.png" class="sarafan">
                    <p class="news__img-caption">Photo by Timur Emek</p>
                    <h2 class="news__subtitle">Fall Date-Night Outfit Ideas: From Office to Evening</h2>
                    <p>Give your 9-to-5 skirt new life after hours by styling it with a half-tucked white T-shirt and printed stiletto heels.</p>
                    <img src="/images/longread4-10-2x.png" class="sarafan">
                    <p class="news__img-caption">Photo by Timur Emek</p>
                    <h2 class="news__subtitle">Fall Date-Night Outfit Ideas: From Office to Evening</h2>
                    <p>Bring that '70s vibe onto the evening scene with trousers that have an exaggerated flared shape and a white blouse that's just as over-the-top.</p>
                    <img src="/images/longread4-11-2x.png" class="sarafan">
                    <p class="news__img-caption">Photo by Edward Berthelot</p>
                    <h2 class="news__subtitle">Fall Date-Night Outfit Ideas: From Office to Evening</h2>
                    <p>Layer a long-sleeved blouse underneath a breezy midi dress and slip on some sky-high heels.</p>
                    <img src="/images/longread4-12-2x.png" class="sarafan">
                    <p class="news__img-caption">Photo by Kirstin Sinclair</p><br>
                    <p class="news__img-caption">The source: https://www.glamour.com/gallery/11-fall-date-night-outfit-ideas-that-are-totally-flattering</p>
                </div>
                <div class="sarafan-floor" />
            </div>
        </div>
        <Footer @contactShow="openModal('contact')" />
        <signin v-if="signInShow" @close="closeModal('signIn')" />
        <contact v-if="contactShow" @close="closeModal('contact')" />
    </div>
</template>
<script>
    import Header from '@/components/landing/Header'
    import Footer from '@/components/landing/Footer'
    import signin from '@/components/landing/signin'
    import contact from '@/components/landing/contact'
    import plugin from '@/mixins/plugin'
    export default {
        components: {
            Header, Footer, signin, contact
        },
        mixins: [plugin],
        metaInfo() {
            return {
                title: this.meta.title,
                meta: [
                    { name: 'description', content: this.meta.description },
                    { name: 'keywords', content: this.meta.keywords },
                    { itemprop: 'name', content: (this.meta.schema_name !== '' ? this.meta.schema_name : this.meta.title) },
                    { itemprop: 'description', content: (this.meta.schema_description !== '' ? this.meta.schema_description : this.meta.description) }
                ]
            }
        },
        data() {
            return {
                meta: {
                    title: '11 Fall Date-Night Outfit Ideas That Feel Fresh',
                    description: '',
                    keywords: '',
                    schema_name: '',
                    schema_description: ''
                },
                signInShow: false,
                contactShow: false
            }
        },
        created() {
            window.addEventListener('load', () => {
                let footer = document.querySelector('footer')
                console.log(footer)

                let div = document.createElement('div')
                div.id = 'otm_ad_1897'
                let script = document.createElement('script')
                script.src = 'https://ad.ssp.otm-r.com/ad-tag.js?s=1897&w=728&h=90'
                div.appendChild(script)
                document.body.appendChild(div)
                footer.parentNode.insertBefore(div, footer)
            })
        },
        destroyed() {
            document.getElementsByTagName('sarafan-cart')[0].remove()
            if (document.getElementsByTagName('sarafan-cart').length === 1) {
                document.getElementsByTagName('sarafan-cart')[0].remove()
            }
        },
        methods: {
            openModal(name, event = false) {
                if (name === 'signUp') {
                    this.typeAccount = event
                }
                this[name + 'Show'] = true
                document.getElementsByTagName('html')[0].classList.add('is-clipped')
            },
            closeModal(name) {
                this[name + 'Show'] = false
                document.getElementsByTagName('html')[0].classList.remove('is-clipped')
            }
        }
    }
</script>