<template>
    <section class="highlights">
        <div class="container">
            <div class="highlights__block">
                <h3 class="blue-subtitle" v-html="$t('index.highlights.title')" />
                <h2 class="index-subtitle">{{ $t('index.highlights.subtitle') }}</h2>
                <p class="index-description">{{ $t('index.highlights.description') }}</p>
            </div>
        </div>
    </section>
</template>

<script>
    import ClickOutside from 'vue-click-outside'

    export default {
        directives: {
            ClickOutside
        },
        data() {
            return {
                hint: false
            }
        }
    }
</script>