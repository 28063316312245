<template>
    <div class="message-block__wrapper">
        <div v-if="messageShow" class="message-block">
            {{ $t('message.title') }}
            <router-link to="/privacy-policy">{{ $t('message.privacyPolicy') }}</router-link>
            <button type="button" class="message-block__close" @click="closeMessage">{{ $t('message.button') }}</button>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                messageShow: false
            }
        },
        mounted() {
            if (!this.$cookies.get('message')) {
                this.messageShow = true
            }
        },
        methods: {
            closeMessage() {
                this.messageShow = false
                this.$cookies.set('message', true)
            }
        }
    }
</script>