<template>
    <section class="video-block">
        <div class="container">
            <h2 class="title">Smart TV</h2>
            <div class="video-block__wrapper">
                <LazyVideo ref="video" :sources="['/videos/smarttv-video.webm', '/videos/smarttv-video.mp4']"
                           poster="/images/smarttv-poster.png" :attrs="{controls:false}" />
                <div ref="video-button" class="video-block__wrapper-button">
                    <button type="button" class="video-block__watch-button" @click="playVideo('video')">
                        <b-icon icon="play" />
                        Watch video
                    </button>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        methods: {
            playVideo(ref) {
                this.$refs[ref].$el.setAttribute('controls', 'true')
                setTimeout(() => {
                    this.$refs[ref].$el.play()
                    this.$refs[ref + '-button'].style.display = 'none'
                }, 0)
            }
        }
    }
</script>