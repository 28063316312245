<template>
    <div class="main-page">
        <first-screen />
        <Insights />
        <image-gallery />
        <hardware-system />
        <Cases />
        <question-section />
        <Footer />
    </div>
</template>

<script>
    import firstScreen from '@/components/landing/main/firstScreen.vue'
    import Insights from '@/components/landing/main/Insights.vue'
    import ImageGallery from '@/components/landing/main/ImageGallery.vue'
    import HardwareSystem from '@/components/landing/main/HardwareSystem.vue'
    import Cases from '@/components/landing/main/Cases.vue'
    import QuestionSection from '@/components/landing/main/QuestionSection.vue'
    import Footer from '@/components/landing/main/Footer.vue'

    export default {
        components: { HardwareSystem, ImageGallery, Insights, firstScreen, Cases, QuestionSection, Footer }
    }
</script>
