<template>
    <section id="request" class="index-request">
        <div class="container">
            <h2 class="index-title">{{ $t('index.request.title') }}</h2>
            <div v-if="showBlock === 'form'" class="index-request__form">
                <div class="index-request__input-block">
                    <input v-model="name.value" type="text" class="index-request__input" :placeholder="$t('main.question.name')"
                           @input="name.error = false">
                    <div v-if="name.error" class="index-request__error">{{ $t('main.question.enterName') }}</div>
                </div>
                <div class="index-request__input-block">
                    <input v-model="email.value" type="text" class="index-request__input" :placeholder="$t('main.question.email')"
                           @input="email.error = false">
                    <div v-if="email.error" class="index-request__error">{{ $t('main.question.enterEmail') }}</div>
                </div>
                <div class="index-request__input-block">
                    <textarea v-model="message.value" class="index-request__textarea" rows="4"
                              :placeholder="$t('main.question.message')" />
                </div>
                <button type="button" class="index-request__button" @click="sendRequest">
                    {{ $t('index.request.start') }}
                </button>
            </div>
            <div v-else class="index-request__complete">{{ $t('main.question.complete') }}</div>
        </div>
    </section>
</template>

<script>
    export default {
        data() {
            return {
                showBlock: 'form',
                name: {
                    value: '',
                    error: false
                },
                email: {
                    value: '',
                    error: false
                },
                message: {
                    value: '',
                    error: false
                },
                send: false
            }
        },
        methods: {
            async sendRequest() {
                if (this.send) {
                    return false
                }

                let error = false
                if (this.name.value === '') {
                    this.name.error = true
                    error = true
                }
                if (!this.email.value.match(/^\S+@\S+.\S+$/)) {
                    this.email.error = true
                    error = true
                }
                if (error) {
                    return false
                }

                this.send = true
                let fields = {
                    subject: 'Сообщение с сайта sarafan.tech',
                    body: `Страница: Video Data Service<br>` +
                        `Имя: ${this.name.value}<br>` +
                        `Почта: ${this.email.value}<br>` +
                        `Сообщение: ${this.message.value}`
                }
                let res = await this.$api.createRequest(fields)
                if (res) {
                    if (res.error) {
                        this.$buefy.toast.open({
                            message: res.error,
                            type: 'is-danger'
                        })
                    } else {
                        this.showBlock = 'complete'
                    }
                }
                this.send = false
            }
        }
    }
</script>