<template>
    <div class="privacy-policy-page">
        <Header />
        <div class="container">
            <div class="text-block">
                <h2 class="title">{{ $t('privacyPolicy.title') }}</h2>
                <p><b>{{ $t('privacyPolicy.updated') }}: 12.02.2019</b></p>
                <p v-html="$t('privacyPolicy.description')" />
                <p class="subtitle">{{ $t('privacyPolicy.block1Title') }}</p>
                <p v-html="$t('privacyPolicy.block1Description')" />
                <p class="subtitle">{{ $t('privacyPolicy.block2Title') }}</p>
                <p v-html="$t('privacyPolicy.block2Description')" />
                <p class="subtitle">{{ $t('privacyPolicy.block3Title') }}</p>
                <p v-html="$t('privacyPolicy.block3Description')" />
                <p class="subtitle">{{ $t('privacyPolicy.block4Title') }}</p>
                <p v-html="$t('privacyPolicy.block4Description')" /><br>
                <p><b>{{ $t('privacyPolicy.block41Title') }}</b></p><br>
                <p v-html="$t('privacyPolicy.block41Description')" /><br>
                <p><b>{{ $t('privacyPolicy.block411Title') }}</b></p><br>
                <p v-html="$t('privacyPolicy.block411Description')" /><br>
            </div>
            <table class="text-block__table">
                <tr>
                    <th>{{ $t('privacyPolicy.table.column1Title') }}</th>
                    <th>{{ $t('privacyPolicy.table.column2Title') }}</th>
                    <th>{{ $t('privacyPolicy.table.column3Title') }}</th>
                    <th>{{ $t('privacyPolicy.table.column4Title') }}</th>
                </tr>
                <tr>
                    <td>.sarafan.tech</td>
                    <td>_csrf-frontend-mylook</td>
                    <td>1 {{ $t('privacyPolicy.table.month') }}</td>
                    <td v-html="$t('privacyPolicy.table.description1')" />
                </tr>
                <tr>
                    <td>.sarafan.tech</td>
                    <td>_language</td>
                    <td>1 {{ $t('privacyPolicy.table.month') }}</td>
                    <td v-html="$t('privacyPolicy.table.description2')" />
                </tr>
                <tr>
                    <td>.sarafan.tech</td>
                    <td>
                        _identity-backend<br>
                        advanced-backend
                    </td>
                    <td>1 {{ $t('privacyPolicy.table.month') }}</td>
                    <td v-html="$t('privacyPolicy.table.description3')" />
                </tr>
                <tr>
                    <td>.sarafan.tech</td>
                    <td>advanced-frontend-mylook</td>
                    <td>1 {{ $t('privacyPolicy.table.month') }}</td>
                    <td v-html="$t('privacyPolicy.table.description4')" />
                </tr>
                <tr>
                    <td>.sarafan.tech</td>
                    <td>_ym_isad</td>
                    <td>24 {{ $t('privacyPolicy.table.hours') }}</td>
                    <td v-html="$t('privacyPolicy.table.description5')" />
                </tr>
                <tr>
                    <td>.sarafan.tech</td>
                    <td>_ym_uid</td>
                    <td>180 {{ $t('privacyPolicy.table.days') }}</td>
                    <td v-html="$t('privacyPolicy.table.description6')" />
                </tr>
                <tr>
                    <td />
                    <td>_ym_d</td>
                    <td>180 {{ $t('privacyPolicy.table.days') }}</td>
                    <td v-html="$t('privacyPolicy.table.description7')" />
                </tr>
                <tr>
                    <td />
                    <td>_ym_visors_*</td>
                    <td>30 {{ $t('privacyPolicy.table.minutes') }}</td>
                    <td v-html="$t('privacyPolicy.table.description8')" />
                </tr>
                <tr>
                    <td>.sarafan.tech</td>
                    <td>ya-disable</td>
                    <td>180 {{ $t('privacyPolicy.table.days') }}</td>
                    <td v-html="$t('privacyPolicy.table.description9')" />
                </tr>
            </table><br><br>
            <div class="text-block">
                <p><b>{{ $t('privacyPolicy.block412Title') }}</b></p>
                <p v-html="$t('privacyPolicy.block412Description')" />
                <p class="subtitle">{{ $t('privacyPolicy.block42Title') }}</p>
                <p v-html="$t('privacyPolicy.block42Description')" />
                <p><b>{{ $t('privacyPolicy.block421Title') }}</b></p>
                <p v-html="$t('privacyPolicy.block421Description')" />
            </div>
            <table class="text-block__table">
                <tr>
                    <th>{{ $t('privacyPolicy.table.column1Title') }}</th>
                    <th>{{ $t('privacyPolicy.table.column2Title') }}</th>
                    <th>{{ $t('privacyPolicy.table.column3Title') }}</th>
                    <th>{{ $t('privacyPolicy.table.column4Title') }}</th>
                </tr>
                <tr>
                    <td>.sarafan.tech</td>
                    <td>_csrf-frontend-mylook</td>
                    <td>1 {{ $t('privacyPolicy.table.month') }}</td>
                    <td v-html="$t('privacyPolicy.table.description1')" />
                </tr>
                <tr>
                    <td>.sarafan.tech</td>
                    <td>_language</td>
                    <td>1 {{ $t('privacyPolicy.table.month') }}</td>
                    <td v-html="$t('privacyPolicy.table.description2')" />
                </tr>
                <tr>
                    <td>.sarafan.tech</td>
                    <td>
                        _identity-backend<br>
                        advanced-backend
                    </td>
                    <td>1 {{ $t('privacyPolicy.table.month') }}</td>
                    <td v-html="$t('privacyPolicy.table.description3')" />
                </tr>
                <tr>
                    <td>.sarafan.tech</td>
                    <td>advanced-frontend-mylook</td>
                    <td>1 {{ $t('privacyPolicy.table.month') }}</td>
                    <td v-html="$t('privacyPolicy.table.description4')" />
                </tr>
                <tr>
                    <td>.sarafan.tech</td>
                    <td>_ym_isad</td>
                    <td>24 {{ $t('privacyPolicy.table.hours') }}</td>
                    <td v-html="$t('privacyPolicy.table.description5')" />
                </tr>
                <tr>
                    <td>.sarafan.tech</td>
                    <td>_ym_uid</td>
                    <td>180 {{ $t('privacyPolicy.table.days') }}</td>
                    <td v-html="$t('privacyPolicy.table.description6')" />
                </tr>
                <tr>
                    <td />
                    <td>_ym_d</td>
                    <td>180 {{ $t('privacyPolicy.table.days') }}</td>
                    <td v-html="$t('privacyPolicy.table.description7')" />
                </tr>
                <tr>
                    <td />
                    <td>_ym_visors_*</td>
                    <td>30 {{ $t('privacyPolicy.table.minutes') }}</td>
                    <td v-html="$t('privacyPolicy.table.description8')" />
                </tr>
                <tr>
                    <td>.sarafan.tech</td>
                    <td>ya-disable</td>
                    <td>180 {{ $t('privacyPolicy.table.days') }}</td>
                    <td v-html="$t('privacyPolicy.table.description9')" />
                </tr>
            </table><br><br>
            <div class="text-block">
                <p><b>{{ $t('privacyPolicy.block422Title') }}</b></p>
                <p v-html="$t('privacyPolicy.block422Description')" /><br>
                <p><b>{{ $t('privacyPolicy.block43Title') }}</b></p>
                <p v-html="$t('privacyPolicy.block43Description')" />
            </div><br>
            <table class="text-block__table">
                <tr>
                    <th>{{ $t('privacyPolicy.table.column1Title') }}</th>
                    <th>{{ $t('privacyPolicy.table.column2Title') }}</th>
                    <th>{{ $t('privacyPolicy.table.column3Title') }}</th>
                    <th>{{ $t('privacyPolicy.table.column4Title') }}</th>
                </tr>
                <tr>
                    <td>.sarafan.tech</td>
                    <td>user</td>
                    <td>1 {{ $t('privacyPolicy.table.month') }}</td>
                    <td v-html="$t('privacyPolicy.table2.description1')" />
                </tr>
                <tr>
                    <td>.sarafan.tech</td>
                    <td>session</td>
                    <td>1 {{ $t('privacyPolicy.table.day') }}</td>
                    <td v-html="$t('privacyPolicy.table2.description2')" />
                </tr>
            </table><br><br>
            <div class="text-block">
                <p v-html="$t('privacyPolicy.block43Description2')" />
                <p class="subtitle">{{ $t('privacyPolicy.block5Title') }}</p>
                <p v-html="$t('privacyPolicy.block5Description')" />
                <p class="subtitle">{{ $t('privacyPolicy.block6Title') }}</p>
                <p v-html="$t('privacyPolicy.block6Description')" />
                <p class="subtitle">{{ $t('privacyPolicy.block7Title') }}</p>
                <p v-html="$t('privacyPolicy.block7Description')" />
                <p class="subtitle">{{ $t('privacyPolicy.block8Title') }}</p>
                <p v-html="$t('privacyPolicy.block8Description')" />
                <p class="subtitle">{{ $t('privacyPolicy.block9Title') }}</p>
                <p v-html="$t('privacyPolicy.block9Description')" />
                <p class="subtitle">{{ $t('privacyPolicy.block10Title') }}</p>
                <p v-html="$t('privacyPolicy.block10Description')" />
                <p class="subtitle">{{ $t('privacyPolicy.block11Title') }}</p>
                <p v-html="$t('privacyPolicy.block11Description')" />
                <p class="subtitle">{{ $t('privacyPolicy.block12Title') }}</p>
                <p v-html="$t('privacyPolicy.block12Description')" />
                <p class="subtitle">{{ $t('privacyPolicy.block13Title') }}</p>
                <p v-html="$t('privacyPolicy.block13Description')" />
                <p class="subtitle">{{ $t('privacyPolicy.block14Title') }}</p>
                <p v-html="$t('privacyPolicy.block14Description')" />
                <p class="subtitle">{{ $t('privacyPolicy.block15Title') }}</p>
                <p v-html="$t('privacyPolicy.block15Description')" />
            </div>
        </div>
        <Footer @contactShow="openModal('contact')" />
        <signin v-if="signInShow" @close="closeModal('signIn')" />
        <contact v-if="contactShow" @close="closeModal('contact')" />
    </div>
</template>
<script>
    import Header from '@/components/landing/main/Header.vue'
    import Footer from '@/components/landing/main/Footer.vue'
    import signin from '@/components/landing/signin'
    import contact from '@/components/landing/contact'
    export default {
        metaInfo() {
            return {
                title: this.meta.title,
                meta: [
                    { name: 'description', content: this.meta.description },
                    { name: 'keywords', content: this.meta.keywords },
                    { itemprop: 'name', content: (this.meta.schema_name !== '' ? this.meta.schema_name : this.meta.title) },
                    { itemprop: 'description', content: (this.meta.schema_description !== '' ? this.meta.schema_description : this.meta.description) }
                ]
            }
        },
        components: {
            Header, Footer, signin, contact
        },
        data() {
            return {
                meta: {
                    title: 'Privacy Policy – Sarafan Technology',
                    description: '',
                    keywords: '',
                    schema_name: '',
                    schema_description: ''
                },
                signInShow: false,
                contactShow: false
            }
        },
        methods: {
            openModal(name, event = false) {
                if (name === 'signUp') {
                    this.typeAccount = event
                }
                this[name + 'Show'] = true
                document.getElementsByTagName('html')[0].classList.add('is-clipped')
            },
            closeModal(name) {
                this[name + 'Show'] = false
                document.getElementsByTagName('html')[0].classList.remove('is-clipped')
            },
            disableMetrica() {
                this.$cookies.set('ya-disable', true, 1825 * 24 * 60 * 60 * 1000)
                this.$buefy.toast.open({
                    message: 'Success',
                    type: 'is-success'
                })
            }
        }
    }
</script>