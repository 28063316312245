<template>
    <div id="app">
        <router-view />
        <vue-progress-bar />
    </div>
</template>
<script>
    import { mapState } from 'vuex'

    export default {
        computed: {
            ...mapState(['isRussianIP', 'geolocationLoaded'])
        },
        watch: {
            geolocationLoaded() {
                this.$i18n.locale = this.isRussianIP ? 'ru' : 'en'
            }
        }
    }
</script>
<style lang="scss">
    @import '@/assets/scss/main.scss';
</style>