import VueScrollTo from 'vue-scrollto'

export default {
    methods: {
        $clickLink(hash, path) {
            if (this.$route.path !== path) {
                this.$router.push(path + hash)
            } else {
                VueScrollTo.scrollTo(hash, 700)
                setTimeout(() => {
                    location.href = hash
                }, 700)
            }
        }
    }
}