import Vue from 'vue'
import Vuex from 'vuex'
import api from '@/api/routes'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        isRussianIP: false,
        geolocationLoaded: false
    },
    actions: {
        async detectIPGeolocation({ state }) {
            const res = await api.getGeolocation()
            if (res?.country === 'RU') {
                state.isRussianIP = true
            }
            state.geolocationLoaded = true
        }
    }
})