<template>
    <div :class="'video-first-screen' + (mode === 'play' ? ' bg-none' : '')">
        <video v-if="videoShow" ref="video" class="video-desktop" autoplay
               loop muted>
            <source src="/videos/sarafan-video.webm" type="video/webm">
            <source src="/videos/sarafan-video.mp4" type="video/mp4">
        </video>
        <div v-if="mobileVideoOpen" class="video-mobile">
            <b-icon icon="close" size="is-small" class="close-video-mobile" @click.native="closeVideo" />
            <video ref="videoMobile" controls muted>
                <source src="/videos/sarafan-video.webm" type="video/webm">
                <source src="/videos/sarafan-video.mp4" type="video/mp4">
            </video>
        </div>
        <div class="top-bg" />
        <div class="left-bg" />
        <div class="right-bg" />
        <div class="container">
            <div class="top">
                <div v-if="mode !== 'ended'" class="top-left">
                    <router-link to="/">
                        <div class="logo"><span class="extra-bold">Sarafan </span>Technology</div>
                    </router-link>
                </div>
                <div v-if="mode !== 'ended'" class="top-right">
                    <div class="links">
                        <router-link to="/advertisers">Advertisers</router-link>
                        <router-link to="/video">Video</router-link>
                        <router-link to="/technology">Technology</router-link>
                        <router-link to="/about">About</router-link>
                    </div>
                    <a href="https://sarafan.tech/en/entry" class="sign-in-button">Sign in</a>
                    <div class="video-first-screen__buttons video-first-screen__buttons-top">
                        <b-icon v-if="mode === 'play'" icon="close" size="is-medium" class="close-video"
                                @click.native="closeVideo" />
                        <b-icon icon="menu" class="menu" @click.native="openMenu" />
                    </div>
                </div>
            </div>
            <div v-if="mobileMenuShow" class="header__mobile">
                <div class="header__mobile__top">
                    <div class="header__mobile__left">
                        <router-link to="/">
                            <h1 class="logo"><span class="extra-bold">Sarafan </span>Technology</h1>
                        </router-link>
                    </div>
                    <div class="header__mobile__right">
                        <b-icon icon="close" class="close-icon" @click.native="closeMenu" />
                    </div>
                </div>
                <div class="header__mobile__bottom">
                    <div class="header__mobile__bottom-top">
                        <div class="header__mobile__left">
                            <div class="links">
                                <router-link to="/advertisers">Advertisers</router-link>
                                <router-link to="/video">Video</router-link>
                                <router-link to="/technology">Technology</router-link>
                                <router-link to="/about">About</router-link>
                            </div>
                        </div>
                        <div class="header__mobile__right">
                            <a href="https://sarafan.tech/en/entry" class="sign-in-button">
                                Sign in
                                <b-icon icon="arrow-right" size="is-small" />
                            </a>
                        </div>
                    </div>
                    <div class="header__mobile__bottom-foot">
                        <router-link to="/privacy-policy">Privacy Policy</router-link>
                        <router-link to="/terms-of-use">Terms and Conditions</router-link>
                    </div>
                </div>
            </div>
            <div v-if="mode === 'start'" class="center">
                <h1 class="video-first-screen__title">Turn inspiration into purchase with the help of AI</h1>
                <div class="video-first-screen__buttons">
                    <button type="button" class="video-first-screen__watch-button" @click="playVideo">
                        <b-icon icon="play" />
                        Watch video
                    </button>
                    <div class="video-first-screen__video-block">
                        <video ref="video" muted poster="/images/mobile-video-poster.png"
                               class="video-first-screen__mobile-video">
                            <source src="/videos/sarafan-video.webm" type="video/webm">
                            <source src="/videos/sarafan-video.mp4" type="video/mp4">
                        </video>
                        <div ref="video-button" class="video-first-screen__wrapper-button">
                            <button type="button" class="video-first-screen__watch-button"
                                    @click="playMobileVideo('video')">
                                <b-icon icon="play" />
                                Watch video
                            </button>
                        </div>
                    </div>
                    <button type="button" class="video-first-screen__request-button"
                            @click="$emit('modalShow', true)">
                        Contact us
                        <b-icon icon="arrow-right" />
                    </button>
                </div>
            </div>
            <div v-if="mode === 'ended'" class="center">
                <button type="button" class="video-first-screen__get-button"
                        @click="$emit('modalShow', true)">
                    Get a free demo
                    <b-icon icon="arrow-right" />
                </button>
                <button type="button" class="video-first-screen__repeat-button" @click="playVideo">
                    <b-icon icon="replay" />
                    Repeat
                </button>
            </div>
            <button type="button" :class="'video-first-screen__more' + (mode === 'play' ? ' red' : '')"
                    @click="clickLink('#text')">
                Learn more
                <b-icon icon="arrow-down" />
            </button>
        </div>
    </div>
</template>

<script>
    import VueScrollTo from 'vue-scrollto'

    export default {
        data() {
            return {
                mode: 'start',
                mobileMenuShow: false,
                videoShow: true,
                mobileVideoOpen: false
            }
        },
        mounted() {
            window.addEventListener('resize', this.initVideo)
            this.initVideo()
            this.$refs.video.addEventListener('ended', () => {
                if (window.innerWidth >= 560) {
                    if (this.mode === 'play') {
                        this.mode = 'ended'
                    } else {
                        this.$refs.video.play()
                    }
                }
            })
        },
        destroyed() {
            document.getElementsByTagName('html')[0].classList.remove('is-clipped')
        },
        methods: {
            initVideo() {
                if (window.innerWidth >= 900) {
                    this.videoShow = true
                } else {
                    this.videoShow = false
                    this.mode = 'start'
                }
            },
            clickLink(hash) {
                VueScrollTo.scrollTo(hash, 700)
                setTimeout(() => {
                    location.href = hash
                    this.mode = 'start'
                }, 700)
            },
            playVideo() {
                if (window.innerWidth >= 900) {
                    this.$refs.video.play()
                    this.mode = 'play'
                } else {
                    this.mobileVideoOpen = true
                    document.getElementsByTagName('html')[0].classList.add('is-clipped')
                    this.$nextTick(() => {
                        this.$refs.videoMobile.play()
                    })
                }
            },
            playMobileVideo(ref) {
                this.$refs[ref].play()
                this.$refs[ref].setAttribute('controls', 'true')
                this.$refs[ref + '-button'].style.display = 'none'
            },
            closeVideo() {
                this.mobileVideoOpen = false
                document.getElementsByTagName('html')[0].classList.remove('is-clipped')
                if (window.innerWidth >= 900) {
                    this.mode = 'start'
                }
            },
            openMenu() {
                this.mobileMenuShow = true
                if (window.innerWidth <= 660) {
                    document.getElementsByTagName('html')[0].classList.add('is-clipped')
                }
            },
            closeMenu() {
                this.mobileMenuShow = false
                if (window.innerWidth <= 660) {
                    document.getElementsByTagName('html')[0].classList.remove('is-clipped')
                }
            }
        }
    }
</script>