<template>
    <div class="about-page">
        <firstScreen @signInShow="openModal('signIn')" />
        <columnsText1 />
        <columnsText2 />
        <teamImages />
        <features />
        <contactUs />
        <Footer @contactShow="openModal('contact')" />
        <signin v-if="signInShow" @close="closeModal('signIn')" />
        <contact v-if="contactShow" @close="closeModal('contact')" />
        <sendCV v-if="sendCVShow" @close="closeModal('sendCV')" />
        <message />
    </div>
</template>

<script>
    import firstScreen from '@/components/landing/about/firstScreen.vue'
    import columnsText1 from '@/components/landing/about/columnsText1.vue'
    import columnsText2 from '@/components/landing/about/columnsText2.vue'
    import teamImages from '@/components/landing/about/teamImages.vue'
    import features from '@/components/landing/about/features.vue'
    import contactUs from '@/components/landing/about/contactUs.vue'
    import Footer from '@/components/landing/main/Footer.vue'
    import signin from '@/components/landing/signin.vue'
    import contact from '@/components/landing/contact.vue'
    import sendCV from '@/components/landing/sendCV.vue'
    import message from '@/components/landing/message.vue'

    export default {
        metaInfo() {
            return {
                title: this.meta.title,
                meta: [
                    { name: 'description', content: this.meta.description },
                    { name: 'keywords', content: this.meta.keywords },
                    { itemprop: 'name', content: (this.meta.schema_name !== '' ? this.meta.schema_name : this.meta.title) },
                    { itemprop: 'description', content: (this.meta.schema_description !== '' ? this.meta.schema_description : this.meta.description) }
                ]
            }
        },
        components: {
            firstScreen, columnsText1, columnsText2, teamImages, features, contactUs, Footer, signin, contact, sendCV, message
        },
        data() {
            return {
                meta: {
                    title: 'About – Sarafan Technology',
                    description: '',
                    keywords: '',
                    schema_name: '',
                    schema_description: ''
                },
                signInShow: false,
                contactShow: false,
                sendCVShow: false
            }
        },
        methods: {
            openModal(name) {
                this[`${name}Show`] = true
                document.getElementsByTagName('html')[0].classList.add('is-clipped')
            },
            closeModal(name) {
                this[`${name}Show`] = false
                document.getElementsByTagName('html')[0].classList.remove('is-clipped')
            }
        }
    }
</script>