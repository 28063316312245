<template>
    <div class="technologies-page">
        <firstScreen @signInShow="openModal('signIn')" />
        <capability1 @contactShow="openModal('contact')" />
        <capability2 />
        <features />
        <partners />
        <Footer @contactShow="openModal('contact')" />
        <signin v-if="signInShow" @close="closeModal('signIn')" />
        <contact v-if="contactShow" @close="closeModal('contact')" />
        <message />
    </div>
</template>
<script>
    import firstScreen from '@/components/landing/technologies/firstScreen'
    import capability1 from '@/components/landing/technologies/capability1'
    import capability2 from '@/components/landing/technologies/capability2'
    import features from '@/components/landing/technologies/features'
    import partners from '@/components/landing/technologies/partners'
    import Footer from '@/components/landing/main/Footer'
    import signin from '@/components/landing/signin'
    import contact from '@/components/landing/contact'
    import message from '@/components/landing/message'
    export default {
        metaInfo() {
            return {
                title: this.meta.title,
                meta: [
                    { name: 'description', content: this.meta.description },
                    { name: 'keywords', content: this.meta.keywords },
                    { itemprop: 'name', content: (this.meta.schema_name !== '' ? this.meta.schema_name : this.meta.title) },
                    { itemprop: 'description', content: (this.meta.schema_description !== '' ? this.meta.schema_description : this.meta.description) }

                ]
            }
        },
        components: {
            firstScreen, capability1, capability2, features, partners, Footer, signin, contact, message
        },
        data() {
            return {
                meta: {
                    title: 'Technology – Sarafan Technology',
                    description: '',
                    keywords: '',
                    schema_name: '',
                    schema_description: ''
                },
                signInShow: false,
                contactShow: false
            }
        },
        methods: {
            openModal(name) {
                this[name + 'Show'] = true
                document.getElementsByTagName('html')[0].classList.add('is-clipped')
            },
            closeModal(name) {
                this[name + 'Show'] = false
                document.getElementsByTagName('html')[0].classList.remove('is-clipped')
            }
        }
    }
</script>