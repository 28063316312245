import axios from 'axios'

const api = axios.create({
    baseURL: process.env.VUE_APP_API
})

api.interceptors.request.use(
    config => config,
    error => Promise.reject(error)
)

export default {
    async createRequest(data) {
        try {
            const res = await api.post('sarafan', data)
            return res.data
        } catch (e) {
            return { error: e.response.data.error }
        }
    },
    async getGeolocation() {
        try {
            const res = await api.get('https://ipinfo.io/json?token=b287009bc7f66c')
            return res.data
        } catch (e) {
            return { error: e.response.data.error }
        }
    }
}