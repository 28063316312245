<template>
    <div class="longread-page">
        <div class="container">
            <Header @signInShow="openModal('signIn')" />
            <div class="news">
                <div class="news__top">
                    <div class="news__date">May 16, 2019</div>
                    <h1 class="news__title">
                        Sarafan’s new shopping list – transform your inspiration into a purchase!
                    </h1>
                </div>
                <div class="news__content">
                    <p class="big-size">
                        <i>
                            We're delighted to announce our brand-new product called Shopping List.
                            From now on, viewers can save outfits from pictures and videos and buy them directly
                            from the website they’re reading.
                        </i>
                    </p>
                    <img src="/images/longread2-1.jpg" class="sarafan">
                    <h2 class="news__subtitle">Our story</h2>
                    <p>
                        In 2016 we began developing our AI tool called Sarafan AI. Our engine recognises objects
                        in photos and videos and suggests online stores in which the products
                        used could be purchased.<br><br>
                        Sarafan AI works with a variety of fashion and lifestyle websites by embedding widgets
                        into their photo and video content. In order to access online stores for their
                        favourite products, all the readers need to do is to click the ’Get this look’ option
                        and continue shopping in the online store.
                    </p>
                    <img src="/images/longread2-2.jpg" class="sarafan">
                    <h2 class="news__subtitle">Sarafan Shopping List</h2>
                    <p>
                        Our research has shown that 50% of online users claim that they sometimes shop spontaneously.
                        Sarafan Shopping List has been created for the other, more cautious half.
                        Thanks to Shopping List users can save all their outfits in one “Favourite” cart and come back
                        to it at any time. We’ll make sure that our system provides the user
                        with a variety of purchasing options, up to date discounts and campaigns.
                        Should the items the user chose run out of stock, we’ll ensure to deliver the most similar
                        items elsewhere. Moreover, Shopping List allows the user to share his favourite outfits
                        with friends and family!
                    </p>
                    <h2 class="news__subtitle">The process</h2>
                    <ol>
                        <li>The photos and videos are equipped with a ‘Get this look’ option.</li>
                        <li>
                            Upon choosing to do so, the expandable widget presents the products
                            that the AI has recognised.
                        </li>
                        <li>
                            The users choose their favourite items from the photo or video and save them
                            in the shopping list.
                        </li>
                        <li>
                            The functionality provides an opportunity to purchase identified items one place
                            and look through previously saved looks.
                        </li>
                    </ol>
                    <img src="/images/longread2-3.jpg" class="sarafan">
                    <h2 class="news__subtitle">Integrating the media website</h2>
                    <p>
                        In order to integrate the Shopping list, all you need to do is to add the Sarafan code.
                        Sarafan will overlook all the technical demands and the AI will identify products automatically.
                        Moreover, we’ll make sure the widget placement and the Shopping list are
                        as per the publisher’s instruction. Partners also have the possibility to create their
                        own unique UI design in their personal media account.
                    </p>
                    <h2 class="news__subtitle">FAQ</h2>
                    <p>
                        <span style="line-height: 2"><b><i>Where does the viewer end up buying the product?</i></b></span><br>
                        The viewer purchases the product either via the shopping list or via the online store.
                    </p>
                    <p>
                        <span style="line-height: 2"><b><i>How do you monetise the shopping list?</i></b></span><br>
                        We offer our partners a variety of CPA, CPC, CPS, CPL or CPM revenue-sharing options
                        which can be applied to all campaigns. Partners also have the possibility of mixing two
                        or more options if needed.
                    </p>
                    <p>
                        <span style="line-height: 2"><b><i>How is cashback connected?</i></b></span><br>
                        Publishers have the possibility to opt into Sarafan’s cashback service
                        which will allow partner websites to earn an additional source of revenue.
                    </p>
                    <p>
                        <span style="line-height: 2"><b><i>How much do Sarafan’s services cost?</i></b></span><br>
                        The SaaS solution is integrated and maintained free of charge. Sarafan works
                        with their partner publishers in a flexible revenue sharing model
                        and collects an established commission.
                    </p>
                </div>
            </div>
        </div>
        <Footer @contactShow="openModal('contact')" />
        <signin v-if="signInShow" @close="closeModal('signIn')" />
        <contact v-if="contactShow" @close="closeModal('contact')" />
    </div>
</template>

<script>
    import Header from '@/components/landing/Header.vue'
    import Footer from '@/components/landing/Footer.vue'
    import signin from '@/components/landing/signin.vue'
    import contact from '@/components/landing/contact.vue'
    import plugin from '@/mixins/plugin'

    export default {
        components: {
            Header, Footer, signin, contact
        },
        mixins: [plugin],
        metaInfo() {
            return {
                title: this.meta.title,
                meta: [
                    { name: 'description', content: this.meta.description },
                    { name: 'keywords', content: this.meta.keywords },
                    { itemprop: 'name', content: (this.meta.schema_name !== '' ? this.meta.schema_name : this.meta.title) },
                    { itemprop: 'description', content: (this.meta.schema_description !== '' ? this.meta.schema_description : this.meta.description) }
                ]
            }
        },
        data() {
            return {
                meta: {
                    title: 'Sarafan’s new shopping list – transform your inspiration into a purchase!',
                    description: '',
                    keywords: '',
                    schema_name: '',
                    schema_description: ''
                },
                signInShow: false,
                contactShow: false
            }
        },
        destroyed() {
            document.getElementsByTagName('sarafan-cart')[0].remove()
            if (document.getElementsByTagName('sarafan-cart').length === 1) {
                document.getElementsByTagName('sarafan-cart')[0].remove()
            }
        },
        methods: {
            openModal(name, event = false) {
                if (name === 'signUp') {
                    this.typeAccount = event
                }
                this[`${name}Show`] = true
                document.getElementsByTagName('html')[0].classList.add('is-clipped')
            },
            closeModal(name) {
                this[`${name}Show`] = false
                document.getElementsByTagName('html')[0].classList.remove('is-clipped')
            }
        }
    }
</script>