<template>
    <div class="container container--header">
        <header class="header">
            <div class="header__left">
                <router-link to="/"><h1 class="logo">Sarafan Technology</h1></router-link>
            </div>
            <div class="header__right">
                <div class="links">
                    <button type="button" @click="$clickLink('#features', '/')">Features</button>
                    <router-link to="/advertisers">Advertisers</router-link>
                    <router-link to="/technology">Technology</router-link>
                    <router-link to="/about">About</router-link>
                </div>
                <button v-if="$route.path === '/'" type="button" class="sign-in-button"
                        @click="$clickLink('#request', '/')">
                    Get started
                </button>
                <b-icon icon="menu" class="menu" @click.native="openMenu" />
            </div>
            <div v-if="mobileMenuShow" class="header__mobile">
                <div class="header__mobile__top">
                    <div class="header__mobile__left">
                        <router-link to="/"><h1 class="logo">Sarafan Technology</h1></router-link>
                    </div>
                    <div class="header__mobile__right">
                        <b-icon icon="close" class="close-icon" @click.native="closeMenu" />
                    </div>
                </div>
                <div class="header__mobile__bottom">
                    <div class="header__mobile__left">
                        <div class="links">
                            <button type="button" @click="clickLinkMobile('#features', '/')">
                                Features
                            </button>
                            <router-link to="/advertisers">Advertisers</router-link>
                            <router-link to="/technology">Technology</router-link>
                            <router-link to="/about">About</router-link>
                        </div>
                    </div>
                    <div class="header__mobile__right">
                        <button v-if="$route.path === '/'" type="button" class="sign-in-button"
                                @click="clickLinkMobile('#request', '/')">
                            Get started
                        </button>
                        <a v-else href="https://admin.sarafan.tech/login" target="_blank" class="sign-in-button">
                            Sign in
                        </a>
                    </div>
                </div>
            </div>
        </header>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                mobileMenuShow: false
            }
        },
        destroyed() {
            document.getElementsByTagName('html')[0].classList.remove('is-clipped')
        },
        methods: {
            openModal() {
                this.mobileMenuShow = false
                this.$emit('signInShow', true)
            },
            openMenu() {
                this.mobileMenuShow = true
                if (window.innerWidth <= 660) {
                    document.getElementsByTagName('html')[0].classList.add('is-clipped')
                }
            },
            closeMenu() {
                this.mobileMenuShow = false
                if (window.innerWidth <= 660) {
                    document.getElementsByTagName('html')[0].classList.remove('is-clipped')
                }
            },
            clickLinkMobile(hash, path) {
                this.$clickLink(hash, path)
                this.closeMenu()
            }
        }
    }
</script>