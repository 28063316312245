<template>
    <section :class="'first-screen' + (mode === 'mobile' ? ' mobile-bg' : '')">
        <video v-if="mode === 'desktop'" autoplay loop class="first-screen__video">
            <source src="/videos/video.mp4">
        </video>
        <Header @signInShow="$emit('signInShow', $event)" />
        <div class="container">
            <div class="main-block columns">
                <div class="main-block__left column">
                    <div class="titles">
                        <h1 class="title">{{ $t('technologies.firstScreen.title') }}</h1>
                        <p class="subtitle">{{ $t('technologies.firstScreen.description') }}</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import Header from '@/components/landing/main/Header.vue'

    export default {
        components: {
            Header
        },
        data() {
            return {
                mode: 'desktop'
            }
        },
        created() {
            window.addEventListener('resize', this.initMode)
            this.initMode()
        },
        destroyed() {
            window.removeEventListener('resize', this.initMode)
        },
        methods: {
            initMode() {
                this.mode = window.innerWidth > 768 ? 'desktop' : 'mobile'
            }
        }
    }
</script>