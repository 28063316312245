<template>
    <div class="longread-page">
        <div class="container">
            <Header @signInShow="openModal('signIn')" />
            <div class="news">
                <div class="news__top">
                    <div class="news__date">July 2, 2019</div>
                    <h1 class="news__title">Inside Mark Ronson's Spanish-Style LA House</h1>
                </div>
                <div class="news__content">
                    <p class="big-size">
                        <i>Musician, songwriter and producer Mark Ronson takes us on a tour of his Spanish-style Los Angeles home. His house features a grand player piano,
                            an impressive art collection and a hidden prohibition-era bar behind a bookshelf.</i>
                    </p>
                    <video controls class="longread-page__video video-js vjs-tech vjs-sarafan-skin"><source src="/videos/longread5-video.mp4" type="video/mp4"></video><br><br>
                    <p class="news__img-caption">The source: https://video.architecturaldigest.com/watch/open-door-inside-mark-ronson-s-house</p>
                </div>
                <div class="sarafan-floor" />
            </div>
        </div>
        <Footer @contactShow="openModal('contact')" />
        <signin v-if="signInShow" @close="closeModal('signIn')" />
        <contact v-if="contactShow" @close="closeModal('contact')" />
    </div>
</template>
<script>
    import Header from '@/components/landing/Header'
    import Footer from '@/components/landing/Footer'
    import signin from '@/components/landing/signin'
    import contact from '@/components/landing/contact'
    import plugin from '@/mixins/plugin'
    export default {
        components: {
            Header, Footer, signin, contact
        },
        mixins: [plugin],
        metaInfo() {
            return {
                title: this.meta.title,
                meta: [
                    { name: 'description', content: this.meta.description },
                    { name: 'keywords', content: this.meta.keywords },
                    { itemprop: 'name', content: (this.meta.schema_name !== '' ? this.meta.schema_name : this.meta.title) },
                    { itemprop: 'description', content: (this.meta.schema_description !== '' ? this.meta.schema_description : this.meta.description) }
                ]
            }
        },
        data() {
            return {
                meta: {
                    title: 'Inside Mark Ronson\'s Spanish-Style LA House',
                    description: '',
                    keywords: '',
                    schema_name: '',
                    schema_description: ''
                },
                signInShow: false,
                contactShow: false
            }
        },
        destroyed() {
            document.getElementsByTagName('sarafan-cart')[0].remove()
            if (document.getElementsByTagName('sarafan-cart').length === 1) {
                document.getElementsByTagName('sarafan-cart')[0].remove()
            }
        },
        methods: {
            openModal(name, event = false) {
                if (name === 'signUp') {
                    this.typeAccount = event
                }
                this[name + 'Show'] = true
                document.getElementsByTagName('html')[0].classList.add('is-clipped')
            },
            closeModal(name) {
                this[name + 'Show'] = false
                document.getElementsByTagName('html')[0].classList.remove('is-clipped')
            }
        }
    }
</script>