<template>
    <div class="longread-page">
        <div class="container">
            <Header @signInShow="openModal('signIn')" />
            <div class="news">
                <div class="news__top">
                    <div class="news__date">July 2, 2019</div>
                    <h1 class="news__title">High Design Airbnb Rentals We'd Love to Call Home</h1>
                </div>
                <div class="news__content">
                    <p class="big-size"><i>Escape your cramped apartment for one of these dreamy properties just outside of New York City</i></p>
                    <p>
                        For East Coasters, summer tends to come as a shock: Just as we're getting used to the fresh smell of spring air and easing into longer days, it's suddenly the Fourth of July. Ack.
                        And for those of us who never got around to making reservations for that weekend getaway we totally intended on taking, it becomes a panicked dash to book something. Double ack.
                        But before you settle for any old overpriced hotel room too far from the beach, consider one of these high-design Airbnb rentals, all driving distance or a train ride away
                        from Manhattan. Our criteria for this dream list of homes is as follows: furnishings we wished we owned in our own apartment; a kitchen that inspires us to cook large communal meals
                        for our housemates; and cozy bedrooms and nooks that ensure quality naps throughout the weekend. Above all, these standout listings ignite our voyeuristic tendencies for inspiring spaces,
                        so even if we can't get the gang together for a last-minute getaway, window shopping can be satisfying enough. Check out the following vacation rentals just outside the city
                        that we'd be happy to temporarily call home.
                    </p><br>
                    <p><b>Montauk, New York.</b> This beachy cottage is packed with custom plywood accents and furniture mixed with rich stone and tile, and design-forward decor. Basically, our dream home.</p>
                    <img src="/images/longread5-1-2x.png" class="sarafan">
                    <p class="news__img-caption">Photo courtesy of Airbnb</p><br>
                    <p>
                        <b>South Kortright, New York.</b> From the team behind the Bon Appetit–approved café Table on Ten, this studio adheres to the same relaxed atmosphere as the upstate eatery,
                        but with floor-to-ceiling windows and sweeping views of the Catskills.
                    </p>
                    <img src="/images/longread5-2-2x.png" class="sarafan">
                    <p class="news__img-caption">Photo courtesy of Airbnb</p><br>
                    <p>
                        <b>Cragsmoor, New York.</b> If the soothing minimal spirit of Scandinavian design is your jam, this chalet-style home will be your sanctuary. Not only is the furniture on point,
                        the wall-to-wall views of the Shawangunk Mountains transports guests to a land far, far away.
                    </p>
                    <img src="/images/longread5-3-2x.png" class="sarafan">
                    <p class="news__img-caption">Photo courtesy of Airbnb</p><br>
                    <p>
                        <b>Hudson, New York.</b> Recognize this dreamy rustic kitchen? It was featured on Clever in a tour of this one-bedroom Hudson apartment designed by Zio & Sons founder Anthony D'Argenzio.
                        So, if you have ever wanted to stay in one of the homes featured on our site, here's your chance! PS: A similar unit by Zio is listed here.
                    </p>
                    <img src="/images/longread5-4-2x.png" class="sarafan">
                    <p class="news__img-caption">Photo courtesy of Airbnb</p><br>
                    <p>
                        <b>Bushkill, Pennsylvania.</b> The owners of this chalet-style home in the Poconos are design professionals and avid collectors of the local Shaker-style furniture. If you happen to fall
                        in love with any piece in the house, good news: Everything's for sale.
                    </p>
                    <img src="/images/longread5-5-2x.png" class="sarafan">
                    <p class="news__img-caption">Photo courtesy of Airbnb</p><br>
                    <p>
                        <b>Southampton, New York.</b> The impressive art and design collection in this airy space would make us feel right at home. Bonus points for the retro mint green bathroom
                        and covered sunporch for enjoying the great outdoors year-round.
                    </p>
                    <img src="/images/longread5-6-2x.png" class="sarafan">
                    <p class="news__img-caption">Photo courtesy of Airbnb</p><br>
                    <p>
                        <b>Hudson, New York.</b> Midcentury design meets country farmhouse in this well-appointed guest suite. We especially identify with the homeowner's use of a stainless-steel worktable
                        as a makeshift kitchen island.
                    </p>
                    <img src="/images/longread5-7-2x.png" class="sarafan">
                    <p class="news__img-caption">Photo courtesy of Airbnb</p><br>
                    <p>
                        <b>Hudson, New York.</b> Midcentury design meets country farmhouse in this well-appointed guest suite. We especially identify with the homeowner's use of a stainless-steel worktable
                        as a makeshift kitchen island.
                    </p>
                    <img src="/images/longread5-8-2x.png" class="sarafan">
                    <p class="news__img-caption">Photo courtesy of Airbnb</p><br>
                    <p>
                        <b>Long Branch, New Jersey.</b> This sunny abode is the best of both beach and countryside: It's only a 1.5 mile walk to the shore, and guests are provided with fresh eggs each morning
                        from the resident chickens in the backyard garden.
                    </p>
                    <img src="/images/longread5-9-2x.png" class="sarafan">
                    <p class="news__img-caption">Photo courtesy of Airbnb</p><br>
                    <p>Bellport, New York. With a dream collection of art and design, this property is full of ideas we'd like to steal. Add to that a modern pool and plenty of outdoor space, and we're sold.</p>
                    <img src="/images/longread5-10-2x.png" class="sarafan">
                    <p class="news__img-caption">Photo courtesy of Airbnb</p><br>
                    <p class="news__img-caption">The source: https://www.architecturaldigest.com/gallery/high-design-airbnb-rentals-wed-love-to-call-home</p>
                </div>
                <div class="sarafan-floor" />
            </div>
        </div>
        <Footer @contactShow="openModal('contact')" />
        <signin v-if="signInShow" @close="closeModal('signIn')" />
        <contact v-if="contactShow" @close="closeModal('contact')" />
    </div>
</template>
<script>
    import Header from '@/components/landing/Header'
    import Footer from '@/components/landing/Footer'
    import signin from '@/components/landing/signin'
    import contact from '@/components/landing/contact'
    import plugin from '@/mixins/plugin'
    export default {
        components: {
            Header, Footer, signin, contact
        },
        mixins: [plugin],
        metaInfo() {
            return {
                title: this.meta.title,
                meta: [
                    { name: 'description', content: this.meta.description },
                    { name: 'keywords', content: this.meta.keywords },
                    { itemprop: 'name', content: (this.meta.schema_name !== '' ? this.meta.schema_name : this.meta.title) },
                    { itemprop: 'description', content: (this.meta.schema_description !== '' ? this.meta.schema_description : this.meta.description) }
                ]
            }
        },
        data() {
            return {
                meta: {
                    title: 'High Design Airbnb Rentals We\'d Love to Call Home',
                    description: '',
                    keywords: '',
                    schema_name: '',
                    schema_description: ''
                },
                signInShow: false,
                contactShow: false
            }
        },
        destroyed() {
            document.getElementsByTagName('sarafan-cart')[0].remove()
            if (document.getElementsByTagName('sarafan-cart').length === 1) {
                document.getElementsByTagName('sarafan-cart')[0].remove()
            }
        },
        methods: {
            openModal(name, event = false) {
                if (name === 'signUp') {
                    this.typeAccount = event
                }
                this[name + 'Show'] = true
                document.getElementsByTagName('html')[0].classList.add('is-clipped')
            },
            closeModal(name) {
                this[name + 'Show'] = false
                document.getElementsByTagName('html')[0].classList.remove('is-clipped')
            }
        }
    }
</script>