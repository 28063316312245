<template>
    <div class="advertisers-page">
        <firstScreen @signInShow="openModal('signIn')"
                     @signUpShow="openModal('signUp', 'advertisers')" />
        <columnsText />
        <products />
        <description />
        <features />
        <partners />
        <apply @signUpShow="openModal('signUp', 'advertisers')" />
        <Footer @contactShow="openModal('contact')" />
        <signup v-if="signUpShow" :type-account="typeAccount" @close="closeModal('signUp')" />
        <signin v-if="signInShow" @close="closeModal('signIn')" />
        <contact v-if="contactShow" @close="closeModal('contact')" />
        <message />
    </div>
</template>

<script>
    import firstScreen from '@/components/landing/advertisers/firstScreen.vue'
    import columnsText from '@/components/landing/advertisers/columnsText.vue'
    import products from '@/components/landing/advertisers/products.vue'
    import description from '@/components/landing/advertisers/description.vue'
    import features from '@/components/landing/advertisers/features.vue'
    import partners from '@/components/landing/advertisers/partners.vue'
    import apply from '@/components/landing/advertisers/apply.vue'
    import Footer from '@/components/landing/Footer.vue'
    import signup from '@/components/landing/signup.vue'
    import signin from '@/components/landing/signin.vue'
    import contact from '@/components/landing/contact.vue'
    import message from '@/components/landing/message.vue'

    export default {
        metaInfo() {
            return {
                title: this.meta.title,
                meta: [
                    { name: 'description', content: this.meta.description },
                    { name: 'keywords', content: this.meta.keywords },
                    { itemprop: 'name', content: (this.meta.schema_name !== '' ? this.meta.schema_name : this.meta.title) },
                    { itemprop: 'description', content: (this.meta.schema_description !== '' ? this.meta.schema_description : this.meta.description) }
                ]
            }
        },
        components: {
            firstScreen, columnsText, products, description, features, partners, apply, Footer, signup, signin, contact, message
        },
        data() {
            return {
                meta: {
                    title: 'Advertisers – Sarafan Technology',
                    description: '',
                    keywords: '',
                    schema_name: '',
                    schema_description: ''
                },
                signUpShow: false,
                typeAccount: '',
                signInShow: false,
                contactShow: false
            }
        },
        methods: {
            openModal(name, event = false) {
                if (name === 'signUp') {
                    this.typeAccount = event
                }
                this[name + 'Show'] = true
                document.getElementsByTagName('html')[0].classList.add('is-clipped')
            },
            closeModal(name) {
                this[name + 'Show'] = false
                document.getElementsByTagName('html')[0].classList.remove('is-clipped')
            }
        }
    }
</script>