<template>
    <div class="index-page">
        <first-screen />
        <celebrity />
        <highlights />
        <fanstore />
        <pricing />
        <lab />
        <trusted />
        <featured />
        <request />
        <Footer />
        <message />
    </div>
</template>

<script>
    import firstScreen from '@/components/landing/index/firstScreen.vue'
    import celebrity from '@/components/landing/index/celebrity.vue'
    import highlights from '@/components/landing/index/highlights.vue'
    import fanstore from '@/components/landing/index/fanstore.vue'
    import pricing from '@/components/landing/index/pricing.vue'
    import lab from '@/components/landing/index/lab.vue'
    import trusted from '@/components/landing/index/trusted.vue'
    import featured from '@/components/landing/index/featured.vue'
    import request from '@/components/landing/index/request.vue'
    import Footer from '@/components/landing/main/Footer.vue'
    import analytics from '@/mixins/analytics.vue'
    import message from '@/components/landing/message.vue'

    export default {
        components: {
            firstScreen, celebrity, highlights, fanstore, pricing, lab, trusted, featured, request, Footer, message
        },
        mixins: [analytics],
        metaInfo() {
            return {
                title: this.meta.title,
                meta: [
                    { name: 'description', content: this.meta.description },
                    { name: 'keywords', content: this.meta.keywords },
                    { itemprop: 'name', content: (this.meta.schema_name !== '' ? this.meta.schema_name : this.meta.title) },
                    { itemprop: 'description', content: (this.meta.schema_description !== '' ? this.meta.schema_description : this.meta.description) }
                ]
            }
        },
        data() {
            return {
                meta: {
                    title: 'Video Data Service – Sarafan Technology',
                    description: '',
                    keywords: '',
                    schema_name: '',
                    schema_description: ''
                },
                signUpShow: false,
                typeAccount: '',
                signInShow: false,
                contactShow: false,
                swiperSlide: 1,
                swiperHover: false
            }
        }
    }
</script>