import Vue from 'vue'
import Router from 'vue-router'
import VueScrollTo from 'vue-scrollto'
import store from './store.js'

import notFound from '@/views/NotFound'
import index from '@/views/landing/index'
import main from '@/views/landing/main'
import advertisers from '@/views/landing/advertisers'
import technologies from '@/views/landing/technologies'
import about from '@/views/landing/about'
import privacyPolicy from '@/views/landing/privacyPolicy'
import termsConditions from '@/views/landing/termsConditions'
import video from '@/views/promo/video'
import products from '@/views/landing/products'
import longread from '@/views/landing/longread'
import longread2 from '@/views/landing/longread2'
import longread3 from '@/views/landing/longread3'
import longread4 from '@/views/landing/longread4'
import longread5 from '@/views/landing/longread5'
import longread6 from '@/views/landing/longread6'

Vue.use(Router)
let router = new Router({
    mode: 'history',
    routes: [
        {
            path: '/',
            name: 'Home',
            component: main
        },
        { path: '/en', redirect: '/' },
        { path: '/ru', redirect: '/' },
        { path: '/entry', redirect: '/en/entry' },
        {
            path: '/advertisers',
            name: 'Advertisers',
            component: advertisers
        },
        {
            path: '/technology',
            name: 'Technology',
            component: technologies
        },
        {
            path: '/in-store-analytics',
            name: 'Analytics',
            component: main
        },
        {
            path: '/video-data-service',
            name: 'Video Data Service',
            component: index
        },
        {
            path: '/about',
            name: 'About',
            component: about
        },
        {
            path: '/privacy-policy',
            name: 'Privacy Policy',
            component: privacyPolicy
        },
        {
            path: '/terms-of-use',
            name: 'Terms and Conditions',
            component: termsConditions
        },
        {
            path: '/video',
            name: 'Video',
            component: video
        },
        {
            path: '/products',
            name: 'Products',
            component: products
        },
        {
            path: '/blog/sarafans-new-shopping-list-transform-your-inspiration-into-a-purchase',
            name: 'Longread',
            component: longread
        },
        {
            path: '/blog/sarafans-new-shopping-list-furniture',
            name: 'Longread 2',
            component: longread2
        },
        {
            path: '/blog/the-best-smartphones-for-2019',
            name: 'Longread 3',
            component: longread3
        },
        {
            path: '/blog/11-fall-date-night-outfit-ideas-that-feel-fresh',
            name: 'Longread 4',
            component: longread4
        },
        {
            path: '/blog/high-design-airbnb-rentals-we-love-to-call-home',
            name: 'Longread 5',
            component: longread5
        },
        {
            path: '/blog/inside-mark-ronsons-spanish-style-la-house',
            name: 'Longread 6',
            component: longread6
        },
        {
            path: '*',
            name: 'Page not found',
            component: notFound
        }
    ],
    scrollBehavior(to, from, savedPosition) {
        if (to.hash) {
            VueScrollTo.scrollTo(to.hash, 700)
            return { selector: to.hash }
        } else if (savedPosition) {
            return savedPosition
        } else {
            return { x: 0, y: 0 }
        }
    }
})

function loadImage(url) {
    return new Promise(function(resolve) {
        let img = new Image()
        img.onload = () => {
            resolve(url)
        }
        img.onerror = async () => {
            url = url.replace('.webp', '.png')
            await loadImage(url)
            resolve(url)
        }
        img.src = `/images/${url}`
    })
}

let siteName = ' – Sarafan Technology'
router.beforeResolve(async (to, from, next) => {
    if (!store.state.geolocationLoaded) {
        await store.dispatch('detectIPGeolocation')
    }
    document.title = to.name + siteName
    let images = {
        Home: 'section-a-bg.webp',
        Advertisers: 'section-a-bg-advert.webp',
        Publishers: 'section-a-bg-publish.webp',
        About: 'section-a-bg-about.webp'
    }
    if (images[to.name]) {
        await loadImage(images[to.name])
    }
    next()
})

export default router