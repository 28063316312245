<template>
    <div class="terms-conditions-page">
        <Header />
        <div class="container">
            <div class="text-block">
                <h2 class="title">{{ $t('termsAndConditions.title') }}</h2>
                <p><b>{{ $t('termsAndConditions.updated') }}: 10.01.2019</b></p>
                <p v-html="$t('termsAndConditions.description')" />
                <p class="subtitle">{{ $t('termsAndConditions.block1Title') }}</p>
                <p v-html="$t('termsAndConditions.block1Description')" />
                <p class="subtitle">{{ $t('termsAndConditions.block2Title') }}</p>
                <p v-html="$t('termsAndConditions.block2Description')" />
                <p class="subtitle">{{ $t('termsAndConditions.block3Title') }}</p>
                <p v-html="$t('termsAndConditions.block3Description')" />
                <p class="subtitle">{{ $t('termsAndConditions.block4Title') }}</p>
                <p v-html="$t('termsAndConditions.block4Description')" />
                <p class="subtitle">{{ $t('termsAndConditions.block5Title') }}</p>
                <p v-html="$t('termsAndConditions.block5Description')" />
                <p class="subtitle">{{ $t('termsAndConditions.block6Title') }}</p>
                <p v-html="$t('termsAndConditions.block6Description')" />
                <p class="subtitle">{{ $t('termsAndConditions.block7Title') }}</p>
                <p v-html="$t('termsAndConditions.block7Description')" />
                <p class="subtitle">{{ $t('termsAndConditions.block8Title') }}</p>
                <p v-html="$t('termsAndConditions.block8Description')" />
                <p class="subtitle">{{ $t('termsAndConditions.block9Title') }}</p>
                <p v-html="$t('termsAndConditions.block9Description')" />
                <p class="subtitle">{{ $t('termsAndConditions.block10Title') }}</p>
                <p v-html="$t('termsAndConditions.block10Description')" />
                <p class="subtitle">{{ $t('termsAndConditions.block11Title') }}</p>
                <p v-html="$t('termsAndConditions.block11Description')" />
                <p class="subtitle">{{ $t('termsAndConditions.block12Title') }}</p>
                <p v-html="$t('termsAndConditions.block12Description')" />
                <p class="subtitle">{{ $t('termsAndConditions.block13Title') }}</p>
                <p v-html="$t('termsAndConditions.block13Description')" />
                <p class="subtitle">{{ $t('termsAndConditions.block14Title') }}</p>
                <p v-html="$t('termsAndConditions.block14Description')" />
            </div>
        </div>
        <Footer @contactShow="openModal('contact')" />
        <signin v-if="signInShow" @close="closeModal('signIn')" />
        <contact v-if="contactShow" @close="closeModal('contact')" />
    </div>
</template>
<script>
    import Header from '@/components/landing/main/Header'
    import Footer from '@/components/landing/main/Footer'
    import signin from '@/components/landing/signin'
    import contact from '@/components/landing/contact'
    export default {
        metaInfo() {
            return {
                title: this.meta.title,
                meta: [
                    { name: 'description', content: this.meta.description },
                    { name: 'keywords', content: this.meta.keywords },
                    { itemprop: 'name', content: (this.meta.schema_name !== '' ? this.meta.schema_name : this.meta.title) },
                    { itemprop: 'description', content: (this.meta.schema_description !== '' ? this.meta.schema_description : this.meta.description) }
                ]
            }
        },
        components: {
            Header, Footer, signin, contact
        },
        data() {
            return {
                meta: {
                    title: 'Terms and Conditions – Sarafan Technology',
                    description: '',
                    keywords: '',
                    schema_name: '',
                    schema_description: ''
                },
                signInShow: false,
                contactShow: false
            }
        },
        methods: {
            openModal(name, event = false) {
                if (name === 'signUp') {
                    this.typeAccount = event
                }
                this[name + 'Show'] = true
                document.getElementsByTagName('html')[0].classList.add('is-clipped')
            },
            closeModal(name) {
                this[name + 'Show'] = false
                document.getElementsByTagName('html')[0].classList.remove('is-clipped')
            }
        }
    }
</script>