<template>
    <section id="features" class="celebrity">
        <div class="container">
            <h2 class="index-title">{{ $t('index.celebrity.title') }}</h2>
            <div class="celebrity__content">
                <div class="is-flex">
                    <div class="celebrity__player-image">
                        <img src="/images/celebrity-1.png" alt="">
                    </div>
                    <div class="index-text-block">
                        <div class="index-top-block">
                            <h3 class="blue-subtitle">{{ $t('index.celebrity.block1.title') }}</h3>
                            <h2 class="index-subtitle">{{ $t('index.celebrity.block1.subtitle') }}</h2>
                            <p class="index-description">{{ $t('index.celebrity.block1.description') }}</p>
                        </div>
                        <div class="index-inner-block">
                            <h3 class="blue-subtitle">{{ $t('index.celebrity.block2.title') }}</h3>
                            <h2 class="index-subtitle">{{ $t('index.celebrity.block2.subtitle') }}</h2>
                            <p class="index-description">{{ $t('index.celebrity.block2.description') }}</p>
                        </div>
                    </div>
                </div>
                <div class="is-grid-2">
                    <div class="index-block">
                        <h3 class="blue-subtitle">{{ $t('index.celebrity.block3.title') }}</h3>
                        <h2 class="index-subtitle">{{ $t('index.celebrity.block3.subtitle') }}</h2>
                        <p class="index-description">{{ $t('index.celebrity.block3.description') }}</p>
                        <div class="index-block-image"><img src="/images/celebrity-2.png" alt=""></div>
                    </div>
                    <div class="index-block">
                        <h3 class="blue-subtitle">{{ $t('index.celebrity.block4.title') }}</h3>
                        <h2 class="index-subtitle">{{ $t('index.celebrity.block4.subtitle') }}</h2>
                        <p class="index-description">{{ $t('index.celebrity.block4.description') }}</p>
                        <h5 class="index-price-subtitle">{{ $t('index.earn') }}</h5>
                        <div class="index-block-image"><img src="/images/celebrity-3.png" alt=""></div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import ClickOutside from 'vue-click-outside'

    export default {
        directives: {
            ClickOutside
        },
        data() {
            return {
                hint1: false,
                hint2: false,
                hint3: false,
                hint4: false
            }
        },
        methods: {
            openHint(id) {
                if (this[`hint${id}`]) {
                    this[`hint${id}`] = false
                } else {
                    for (let i = 1; i <= 4; i++) {
                        this[`hint${i}`] = false
                    }
                    this[`hint${id}`] = true
                }
            }
        }
    }
</script>