<template>
    <section class="not-found-page">
        <div class="container">
            <Header @signInShow="openModal('signIn')" />
            <div class="columns not-found-block">
                <div class="column">
                    <h2 class="title">The page you're looking for none available!</h2>
                    <router-link to="/" class="link">Go home page&nbsp;<b-icon icon="arrow-right" /></router-link>
                </div>
                <div class="column page404">
                    <div class="page404__number">404</div>
                    <div class="page404__text">Page not found</div>
                </div>
            </div>
            <signin v-if="signInShow" @close="closeModal('signIn')" />
        </div>
    </section>
</template>
<script>
    import Header from '@/components/landing/Header'
    import signin from '@/components/landing/signin'
    export default {
        components: {
            Header, signin
        },
        data() {
            return {
                signInShow: false
            }
        },
        methods: {
            openModal(name, event = false) {
                if (name === 'signUp') {
                    this.typeAccount = event
                }
                this[`${name}Show`] = true
                document.getElementsByTagName('html')[0].classList.add('is-clipped')
            },
            closeModal(name) {
                this[`${name}Show`] = false
                document.getElementsByTagName('html')[0].classList.remove('is-clipped')
            }
        }
    }
</script>