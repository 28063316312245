<template>
    <section id="request" class="section-request">
        <div class="container-1">
            <h2 class="section-title">{{ $t('main.question.title') }}</h2>
            <p class="section-text">{{ $t('main.question.description') }}</p>
            <div v-if="showBlock === 'form'" class="section-request__form">
                <div class="section-request__input-block">
                    <input v-model="name.value" type="text" class="section-request__input" :placeholder="$t('main.question.name')"
                           @input="name.error = false">
                    <div v-if="name.error" class="index-request__error">{{ $t('main.question.enterName') }}</div>
                </div>
                <div class="index-request__input-block">
                    <input v-model="email.value" type="text" class="section-request__input" :placeholder="$t('main.question.email')"
                           @input="email.error = false">
                    <div v-if="email.error" class="section-request__error">{{ $t('main.question.enterEmail') }}</div>
                </div>
                <div class="index-request__input-block">
                    <textarea v-model="message.value" class="section-request__textarea" rows="4"
                              :placeholder="$t('main.question.message')" />
                </div>
                <button type="button" class="section-request__button" @click="sendRequest">
                    {{ $t('main.question.button') }}
                </button>
            </div>
            <div v-else class="section-request__complete">{{ $t('main.question.complete') }}</div>
        </div>
    </section>
</template>

<script>
    export default {
        data() {
            return {
                showBlock: 'form',
                name: {
                    value: '',
                    error: false
                },
                email: {
                    value: '',
                    error: false
                },
                message: {
                    value: '',
                    error: false
                },
                send: false
            }
        },
        methods: {
            async sendRequest() {
                if (this.send) {
                    return false
                }

                let error = false
                if (this.name.value === '') {
                    this.name.error = true
                    error = true
                }
                if (!this.email.value.match(/^\S+@\S+.\S+$/)) {
                    this.email.error = true
                    error = true
                }
                if (error) {
                    return false
                }

                this.send = true
                let fields = {
                    subject: 'Сообщение с сайта sarafan.tech',
                    body: `Страница: Главная<br>` +
                        `Имя: ${this.name.value}<br>` +
                        `Почта: ${this.email.value}<br>` +
                        `Сообщение: ${this.message.value}`
                }
                let res = await this.$api.createRequest(fields)
                if (res) {
                    if (res.error) {
                        this.$buefy.toast.open({
                            message: res.error,
                            type: 'is-danger'
                        })
                    } else {
                        this.showBlock = 'complete'
                    }
                }
                this.send = false
            }
        }
    }
</script>
<style scoped lang="scss">
.section-request {
    background-color: #F5F5F7;
    padding: 96px 0;
}
.section-title {
    font-weight: 800;
    font-size: 36px;
    text-align: center;
    text-transform: uppercase;
    color: #070209;
    letter-spacing: 2px;
}

.section-text {
    text-align: center;
    color: #637282;
    font-size: 16px;
    margin-bottom: 64px;

}
</style>