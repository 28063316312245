<template>
    <header class="main-header container--header">
        <div class="main-header__left">
            <router-link to="/"><h1 class="logo main-logo">Sarafan Technology</h1></router-link>
        </div>
        <div class="main-header__right">
            <div class="links">
                <router-link to="/">{{ $t('main.header.link1') }}</router-link>
                <router-link to="/video-data-service">{{ $t('main.header.link2') }}</router-link>
                <router-link to="/technology">{{ $t('main.header.link3') }}</router-link>
                <router-link to="/about">{{ $t('main.header.link4') }}</router-link>
            </div>
            <b-icon icon="menu" class="menu" @click.native="openMenu" />
        </div>
        <div v-if="mobileMenuShow" class="main-header__mobile">
            <div class="main-header__mobile__top">
                <div class="main-header__mobile__left">
                    <router-link to="/"><h1 class="logo">Sarafan Technology</h1></router-link>
                </div>
                <div class="main-header__mobile__right">
                    <b-icon icon="close" class="close-icon" @click.native="closeMenu" />
                </div>
            </div>
            <div class="header__mobile__bottom">
                <div class="main-header__mobile__left">
                    <div class="links">
                        <router-link to="/">{{ $t('main.header.link1') }}</router-link>
                        <router-link to="/video-data-service">{{ $t('main.header.link2') }}</router-link>
                        <router-link to="/technology">{{ $t('main.header.link3') }}</router-link>
                        <router-link to="/about">{{ $t('main.header.link4') }}</router-link>
                    </div>
                </div>
            </div>
        </div>
    </header>
</template>

<script>
    export default {
        data() {
            return {
                mobileMenuShow: false
            }
        },
        destroyed() {
            document.getElementsByTagName('html')[0].classList.remove('is-clipped')
        },
        methods: {
            openModal() {
                this.mobileMenuShow = false
                this.$emit('signInShow', true)
            },
            openMenu() {
                this.mobileMenuShow = true
                if (window.innerWidth <= 660) {
                    document.getElementsByTagName('html')[0].classList.add('is-clipped')
                }
            },
            closeMenu() {
                this.mobileMenuShow = false
                if (window.innerWidth <= 660) {
                    document.getElementsByTagName('html')[0].classList.remove('is-clipped')
                }
            },
            clickLinkMobile(hash, path) {
                this.$clickLink(hash, path)
                this.closeMenu()
            }
        }
    }
</script>

<style scoped lang="scss">
.header {
    color: white;
}
.header__mobile {
    color: #ffffff;
    background-color: #212121;
}
</style>