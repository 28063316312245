<template>
    <div class="products-page">
        <Header />
        <section class="products-page-block">
            <div class="container">
                <h2 class="index-title">Everything you need to entertain, educate and inspire your audience</h2>
                <div class="products-grids-block">
                    <div class="product-grid">
                        <h3 class="product-grid__title">Celebrity Recognition</h3>
                        <p class="product-grid__text">{{ 'Enables users to recognize any celebrity in a\xa0video' }}</p>
                    </div>
                    <div class="product-grid">
                        <h3 class="product-grid__title">Soundtracks</h3>
                        <p class="product-grid__text">Recognises the soundtracks in a movie and adds to users` playlists</p>
                    </div>
                    <div class="product-grid">
                        <h3 class="product-grid__title">Stories</h3>
                        <p class="product-grid__text">{{ 'Provides users with the top facts feed about\xa0the films, TV shows and celebrities' }}</p>
                    </div>
                    <div class="product-grid">
                        <h3 class="product-grid__title">Based on</h3>
                        <p class="product-grid__text">{{ 'Provides users with collection of books and\xa0comics related to video' }}</p>
                    </div>
                    <div class="product-grid">
                        <h3 class="product-grid__title">Fanstore</h3>
                        <p class="product-grid__text">Built-in store of video-themed products. A user can shop without leaving the viewing page.</p>
                    </div>
                    <div class="product-grid">
                        <h3 class="product-grid__title">Highlights</h3>
                        <p class="product-grid__text">{{ 'Enables users to rewatch and share on social\xa0networks short clips with video highlights' }}</p>
                    </div>
                    <div class="product-grid">
                        <h3 class="product-grid__title">Auto-tagging</h3>
                        <p class="product-grid__text">Objects` recognition option to integrate relevant contextual Ad</p>
                    </div>
                    <div class="product-grid">
                        <h3 class="product-grid__title">Fast forward</h3>
                        <p class="product-grid__text">Enables users to fast forward through the opening and closing credits</p>
                    </div>
                    <div class="product-grid">
                        <h3 class="product-grid__title">Commercial break</h3>
                        <p class="product-grid__text">{{ 'Provides you with the data of the moments\xa0most suitable for ad integration' }}</p>
                    </div>
                </div>
            </div>
        </section>
        <Footer />
    </div>
</template>
<script>
    import Header from '@/components/landing/Header'
    import Footer from '@/components/landing/index/Footer'
    export default {
        components: {
            Header, Footer
        }
    }
</script>