<template>
    <div class="modal demo-modal is-active">
        <div class="modal-background" />
        <div class="modal-content">
            <div class="box">
                <div v-if="showBlock === 'main'" class="modal-block">
                    <div class="modal__logo"><span class="extra-bold">Sarafan </span>Technology</div>
                    <button class="modal-close is-large" aria-label="close" @click="$emit('close')" />
                    <h3 class="modal__title">Contact us</h3>
                    <div class="modal__input-block modal__input-block--first">
                        <input ref="name" v-model.trim="name.value" type="text" class="input modal__input"
                               placeholder="Your full name">
                    </div>
                    <div class="modal__input-block">
                        <input v-model.trim="company.value" type="text" class="input modal__input"
                               placeholder="Company name (optional)">
                    </div>
                    <div :class="`modal__input-block${email.mode === 'incorrect' ? ' incorrect' : ''}`">
                        <input ref="email" v-model.trim="email.value" type="text" class="input modal__input"
                               placeholder="Business email" @blur="checkEmail">
                    </div>
                    <div :class="`modal__input-block${phone.mode === 'incorrect' ? ' incorrect' : ''}`">
                        <input ref="phone" v-model.trim="phone.value" type="text" class="input modal__input"
                               placeholder="Phone number" @blur="checkPhone">
                    </div>
                    <button type="button" class="modal__submit" @click="submitForm">
                        Contact us
                        <b-icon icon="arrow-right" size="is-small" />
                    </button>
                    <p v-if="email.mode === 'incorrect'" class="modal__text">Email address is not a valid format.</p>
                    <p v-if="phone.mode === 'incorrect'" class="modal__text">Phone number is not a valid format.</p>
                </div>
                <div v-if="showBlock === 'success'" class="modal-block">
                    <button class="modal-close is-large" aria-label="close" @click="$emit('close')" />
                    <h3 class="modal__title">Thank you</h3>
                    <p class="modal__text">Your request has been sent</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                name: {
                    value: '',
                    mode: 'default'
                },
                company: {
                    value: '',
                    mode: 'default'
                },
                email: {
                    value: '',
                    mode: 'default'
                },
                phone: {
                    value: '',
                    mode: 'default'
                },
                showBlock: 'main'
            }
        },
        watch: {
            'phone.value'() {
                this.enterPhone()
            }
        },
        methods: {
            enterPhone() {
                this.phone.value = this.phone.value.replace(/[^0-9+]/, '')
            },
            checkPhone() {
                let re = /^[0-9+]{1,}[0-9-]{9,15}$/
                this.phone.mode = re.test(this.phone.value) ? '' : 'incorrect'
            },
            checkEmail() {
                let re = /\S+@\S+\.\S+/
                this.email.mode = re.test(this.email.value) ? '' : 'incorrect'
            },
            async submitForm() {
                if (window.send) {
                    return false
                }
                window.send = true

                if (!this.checkFields()) {
                    window.send = false
                    return false
                }

                let fields = {
                    type: 'video',
                    email: this.email.value,
                    htmlMessage: `Имя: ${this.name.value}<br>` +
                        `Компания: ${this.company.value}<br>` +
                        `Телефон: ${this.phone.value}<br>`
                }
                let res = await this.$api.createRequest(fields)
                if (res) {
                    if (res.error) {
                        this.$buefy.toast.open({
                            message: res.error,
                            type: 'is-danger'
                        })
                    } else {
                        this.showBlock = 'success'
                    }
                }

                window.send = false
            },
            checkFields() {
                let reqArr = ['name', 'email', 'phone']
                for (let i = 0; i < reqArr.length; i++) {
                    if (this[reqArr[i]].value === '') {
                        this.$buefy.toast.open({
                            message: 'Field ' + reqArr[i] + ' required',
                            type: 'is-danger'
                        })
                        this.$refs[reqArr[i]].focus()
                        return false
                    }
                }

                if (this.email.mode === 'incorrect') {
                    this.$buefy.toast.open({
                        message: 'Please enter a valid e-mail',
                        type: 'is-danger'
                    })
                    return false
                }
                if (this.phone.mode === 'incorrect') {
                    this.$buefy.toast.open({
                        message: 'Please enter a valid phone',
                        type: 'is-danger'
                    })
                    return false
                }
                return true
            }
        }
    }
</script>