<template>
    <section class="main-first-screen">
        <Header />
        <div class="container-1">
            <div class="main-first-screen__center">
                <p class="introducing_text">{{ $t('main.firstScreen.title') }}</p>
                <h1 class="introducing-title">{{ $t('main.firstScreen.description') }}</h1>
                <button class="learn-button" @click="$clickLink('#request', '/')">
                    {{ $t('main.firstScreen.button') }}
                </button>
                <div class="products-container">
                    <div class="product-ui">
                        <img src="/images/product-ui.png" alt="">
                    </div>
                    <div class="text-container">
                        <p class="product-container__text-message">{{ $t('main.firstScreen.bottomDescription') }}</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import Header from '@/components/landing/main/Header.vue'
    export default {
        components: { Header }
    }
</script>