<script>
    export default {
        created() {
            /*let script = document.createElement('script');
            script.innerHTML = '(function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};\n' +
				'   m[i].l=1*new Date();k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})\n' +
				'   (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");\n' +
				'   ym(26285616, "init", {\n' +
				'        clickmap:true,\n' +
				'        trackLinks:true,\n' +
				'        accurateTrackBounce:true\n' +
				'   });';
            document.body.appendChild(script);*/
        }
    }
</script>