<template>
    <section class="first-screen">
        <Header @signInShow="$emit('signInShow', $event)" />
        <div class="container">
            <div class="main-block columns is-12 is-4-widescreen">
                <div class="main-block__left column">
                    <div class="titles">
                        <p class="subtitle">{{ $t('about.firstScreen.quote') }}</p>
                        <h1 class="title"><b>{{ $t('about.firstScreen.name') }}</b> CEO</h1>
                    </div>
                </div>
                <div class="main-block__right column is-12 is-8-widescreen">
                    <picture class="image-team">
                        <source type="image/webp" srcset="/images/team.webp">
                        <img src="/images/team.png" alt="">
                    </picture>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import Header from '@/components/landing/main/Header.vue'

    export default {
        components: {
            Header
        }
    }
</script>